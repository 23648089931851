import React, { useEffect, useState } from 'react'
import SideBar from '../../../Components/Utilisateur/SideBar'
import Header from '../../../Components/Utilisateur/Header'
import {EditOutlined, DeleteOutlined, ExclamationCircleFilled,EllipsisOutlined} from '@ant-design/icons'
import {Modal} from 'antd'
import axios from 'axios'
import { api } from '../../../api'
import { Button, message, Space, Dropdown } from 'antd';
import { PlusOutlined } from '@ant-design/icons';


const CodeJournale = () => {

  const user_id = sessionStorage.getItem('admin_id');
  const [loading, setLoading] = useState(true);

  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Code journal ajouté avec succès !',
    });
  };

  const successSuppression = () => {
    messageApi.open({
      type: 'success',
      content: 'Code journal supprimé avec succès !',
    });
  };

  const successMod = () => {
    messageApi.open({
      type: 'success',
      content: 'Code journal modifié avec succès !',
    });
  };

  const items = (journal) => [
    {
      label: <p className='f font-semibold hover:text-primary' onClick={() => showModalMod(journal)}>Modifier</p>,
      key: '0',
    },

    {
      label: <p className='f font-semibold hover:text-red-500' onClick={() => showConfirm(journal)}>Supprimer</p>,
      key: '0',
    }
  ];

  const [open, setOpen] = useState(false);
  const [openModalMod, setOpenModalMod] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [confirmLoadingMod, setConfirmLoadingMod] = useState(false);
  const [codeJournale, setCodeJournale] = useState({
    libelle: '',
    abreviation: ''
  })

  const [codeJournaleMod, setCodeJournaleMod] = useState({
    id: '',
    libelle: '',
    abreviation: ''
  })

  const { confirm } = Modal;
  
  // Modal de suppression
  const showConfirm = (journal) => {
    confirm({
      title: 'Voulez-vous vraiment supprimer ce code journal ?',
      icon: <ExclamationCircleFilled />,
      content: (
        <div>
          {/* <p>Code: {journal.id}</p>
          <p>Description: {journal.abreviation}</p>
          <p>Description: {journal.libelle}</p> */}
          {/* <p>Contenu du modal</p> */}
        </div>
      ),
      okText: 'Oui, Supprimer', // Texte personnalisé pour le bouton OK
      cancelText: 'Non, Annuler',
      okButtonProps: {
        style: { backgroundColor: 'red', borderColor: 'red', color: 'white' }, // Bouton OK en rouge
      },
      onOk() {
        // alert('Supprimé')
        // Fonction de suppression un code journal
        supCodeJournalPerso(journal.id)

      },
      onCancel() {
        // alert('Annulé')
      },
    });
  };

  const showModal = () => {
    setOpen(true);
  };

  const showModalMod = (journal) => {
    setOpenModalMod(true);
    setCodeJournaleMod({
      ...codeJournaleMod,
      libelle: journal.libelle,
       abreviation: journal.abreviation, id: journal.id
    });

  };

  // const data = [
  //   { id: 1, abreviation: 'CA', libelle: 'Caisse' },
  //   { id: 2, abreviation: 'BQ', libelle: 'Banque' },
  //   { id: 3, abreviation: 'VC', libelle: 'Vente à credit' },
  //   { id: 3, abreviation: 'AC', libelle: 'Achat à crédit' },
  // ];

  const [data, setData] = useState([])
  const [dataPrive, setDataPrive] = useState([])
  // const dataPrive = [
  //   { id: 1, abreviation: 'CA', libelle: 'Caisse privé' },
  //   { id: 2, abreviation: 'BQ', libelle: 'Banque privé' },
  //   { id: 3, abreviation: 'VC', libelle: 'Vente à credit privé' },
  //   { id: 3, abreviation: 'AC', libelle: 'Achat à crédit privé' },
  // ];

  // Tous les codes journals globals
  const getAllCodeJournal = async () => {
    try {
      const response = await axios.get(api + '/api/allCodeJournalGlobalPrivee/' + user_id);
      setData(response.data);
      setLoading(false);

    } catch (error) {
      console.error('Error fetching code journal:', error);
    }
  };

  // Tous les codes journals perso
  // const getAllCodeJournalPerso = async () => {
  //   try {
  //     const response = await axios.get(api + '/api/allCodeJournalPerso');
  //     setDataPrive(response.data);
  //     setLoading(false);

  //   } catch (error) {
  //     console.error('Error fetching code journal:', error);
  //   }
  // };

  useEffect(() => {
    getAllCodeJournal();
  }, []);

  // Suppression d'un code journal perso
  const supCodeJournalPerso = async (codeJournal_id) => {
    try {
      axios.post(api + `/api/supCodeJournal/${codeJournal_id}`)
          .then(res => {
              successSuppression();
              // Gérer la réponse en cas de succès
              // console.log('Suppression du code journal réussie:', res.data);
              getAllCodeJournal()
              
          })
          .catch(err => {
              // Gérer les erreurs de la requête
              if (err.response) {
                  // Le serveur a répondu avec un code de statut différent de 2xx
                  console.error('Erreur réponse serveur:', err.response.data);
              } else if (err.request) {
                  // La requête a été faite, mais aucune réponse reçue
                  console.error('Aucune réponse reçue:', err.request);
              } else {
                  // Autre erreur lors de la configuration de la requête
                  console.error('Erreur lors de la requête:', err.message);
              }
          });
    } catch (error) {
        // Gérer les erreurs inattendues dans le bloc try
        console.error('Erreur inattendue:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCodeJournale({
      ...codeJournale,
      [name]: value,
    });
  
  }

  const handleInputChangeMod = (e) => {
    const { name, value } = e.target;
    setCodeJournaleMod({
      ...codeJournaleMod,
      [name]: value,
    });

  }

  const handleOk = () => {
    setConfirmLoading(true);

    const formData = new FormData();
    formData.append('libelle', codeJournale.libelle);
    formData.append('abreviation', codeJournale.abreviation);
    formData.append('user_id', user_id) 
    formData.append('etat', 'privee')
    
    try {
      axios.post(api + '/api/ajoutCodeJournal', formData)
          .then(res => {
              success();
              // Gérer la réponse en cas de succès
              console.log('Ajout code journal réussie:', res.data);
              getAllCodeJournal()
              setCodeJournale({
                ...codeJournale,
                libelle: '',
                 abreviation: ''
              });
              
          })
          .catch(err => {
              // Gérer les erreurs de la requête
              if (err.response) {
                  // Le serveur a répondu avec un code de statut différent de 2xx
                  console.error('Erreur réponse serveur:', err.response.data);
              } else if (err.request) {
                  // La requête a été faite, mais aucune réponse reçue
                  console.error('Aucune réponse reçue:', err.request);
              } else {
                  // Autre erreur lors de la configuration de la requête
                  console.error('Erreur lors de la requête:', err.message);
              }
          });
    } catch (error) {
        // Gérer les erreurs inattendues dans le bloc try
        console.error('Erreur inattendue:', error);
    }

    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleOkMod = () => {
    setConfirmLoadingMod(true);

    const formData = new FormData();
    formData.append('libelle', codeJournaleMod.libelle);
    formData.append('abreviation', codeJournaleMod.abreviation);
    
    try {
      axios.post(api + `/api/modCodeJournal/${codeJournaleMod.id}`, formData)
          .then(res => {
              successMod();
              // Gérer la réponse en cas de succès
              // console.log('Ajout code journal réussie:', res.data);
              getAllCodeJournal()
              
          })
          .catch(err => {
              // Gérer les erreurs de la requête
              if (err.response) {
                  // Le serveur a répondu avec un code de statut différent de 2xx
                  console.error('Erreur réponse serveur:', err.response.data);
              } else if (err.request) {
                  // La requête a été faite, mais aucune réponse reçue
                  console.error('Aucune réponse reçue:', err.request);
              } else {
                  // Autre erreur lors de la configuration de la requête
                  console.error('Erreur lors de la requête:', err.message);
              }
          });
    } catch (error) {
        // Gérer les erreurs inattendues dans le bloc try
        console.error('Erreur inattendue:', error);
    }

    setTimeout(() => {
      setOpenModalMod(false);
      setConfirmLoadingMod(false);
    }, 2000);
  };

  const handleCancel = () => {
    // console.log('Clicked cancel button');
    setOpen(false);
  };

  const handleCancelMod = () => {
    // console.log('Clicked cancel button');
    setOpenModalMod(false);
  };

  return (
    <div className='bg flex flex-row' style={{ height: '100vh' }}>
      {contextHolder}
      <div>
        <SideBar />
      </div>
      <div className='bg w-full' style={{ background: '#F9F9FC' }}>
        <div>
          <Header titre={'Code journal'}/>
        </div>
        <div className='pl-8 pr-7 py-4'>
          <div className='flex justify-between'>
            <div>
              
            </div>
            <div>
              <button onClick={showModal} className='px-4 py-1 font-semibold bg-primary text-white duration-300 border-primary rounded-lg border-2'><PlusOutlined className='font-semibold'/> Ajouter</button>
            </div>
          </div>
          {/* Table */}
          {/* <div className="overflow-x-auto mt-5 rounded-xl">
          
            <table className="min-w-full bg-white border border-gray-200">
              <thead>
                <tr className="bg-gray-300 border-b">
                  <th className="py-2 px-4 text-left">Abréviation</th>
                  <th className="py-2 px-4 text-left">Libellé</th>
                  <th className="py-2 px-4 text-left">Actions</th>
                </tr>
              </thead>
              <tbody>
              {loading ? (
              // Spinner de chargement avec TailwindCSS
              <>
              <tr>
                <td colSpan='3'>
                  <div className="flex justify-center items-center h-32">
                    <div className="w-10 h-10 border-4 border-blue-500 border-dashed rounded-full animate-spin"></div>
                  </div>
                </td>
              </tr>
              </>
              ) : (
                <>
                {data.map((journal, index) => (
                  <tr key={journal.id} className={`border-b ${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}>
                    <td className="py-2 px-4">{journal.abreviation}</td>
                    <td className="py-2 px-4">{journal.libelle}</td>
                    <td className="py-2 px-4 space-x-4">
                      {journal.etat === "privee" && (
                          <div className="flex space-x-4">
                            <EditOutlined onClick={() => showModalMod(journal)} style={{ fontSize: '20px', color: '#1890ff' }} className='c cursor-pointer'/>
                            <DeleteOutlined onClick={() => showConfirm(journal)} style={{ fontSize: '20px', color: 'red' }} className='c cursor-pointer'/>
                          </div>
                        )}
                    </td>
                  </tr>
                ))}</>
              )}

                
              </tbody>
            </table>
            
          </div> */}


          {loading ? (
                  // Spinner de chargement avec TailwindCSS
                  <div className="flex justify-center items-center mt-10">
                    <div className="w-12 h-12 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
                  </div>
                ) : (

                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-6 mt-5">
                  {data.map((item) => (
                    <div
                      key={item.id}
                      className="relative max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow-md transform hover:scale-105 hover:shadow-lg transition-all duration-300 ease-in-out dark:bg-gray-800 dark:border-gray-700"
                    >
                      {/* Icône d'arrière-plan (décorative) */}
                      <div className="absolute -top-6 -right-6 h-12 w-12 rounded-full bg-blue-100 opacity-20"></div>
                
                      <div className="flex justify-between items-center mb-4">
                        <h6 className="text-xl font-semibold tracking-tight text-gray-900 dark:text-white">
                          Libellé : <span className="text-blue-600">{item.libelle}</span>
                        </h6>
                        {item.etat === "privee" && (
                        <div>
                          <Dropdown
                            menu={{
                              items: items(item),
                            }}
                            trigger={["click"]}
                          >
                            <a
                              onClick={(e) => e.preventDefault()}
                              className="text-gray-800 font-medium flex items-center space-x-2 hover:text-blue-600 transition-colors"
                            >
                              <Space>
                                <EllipsisOutlined
                                  style={{ fontSize: "25px", color: "#0071BD" }}
                                  className="cursor-pointer"
                                />
                              </Space>
                            </a>
                          </Dropdown>
                        </div>
                        )}
                      </div>
                
                      <p className="mb-3 font-normal text-gray-900 dark:text-gray-400">
                        <span className="font-semibold">Abréviation :</span> {item.abreviation}
                      </p>
                    </div>
                  ))}
                </div>
                
                )}




        </div>
      </div>

      {/* Modal d'ajout */}
      <Modal title={(<div className='text-center'><span className='text-lg'>Ajout d'un code journal</span></div>)}
       open={open} onOk={handleOk} confirmLoading={confirmLoading} onCancel={handleCancel} footer={(
              <div className="flex justify-center space-x-4">
                <button 
                  onClick={handleCancel} 
                  className="px-6 py-2 bg-gray-200 text-black rounded hover:bg-gray-300"
                >
                  Annuler
                </button>
                <button 
                  onClick={handleOk} 
                  className="px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  disabled={confirmLoading}
                >
                  {confirmLoading ? 'En cours...' : 'Enregistrer'}
                </button>
              </div>
            )}
          >
                <div className='space-y-2 mt-8 mb-4'>
                  <label className="font-semibold">Libellé</label>
                  <input
                    id="libelle"
                    name="libelle"
                    type="text"
                    // autoComplete="email"
                    required
                    value={codeJournale.libelle}
                    onChange={handleInputChange}
                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Entrez la libellé"
                  />
                </div>

                <div className='space-y-2 mt-3 mb-8'>
                  <label className="font-semibold">Abréviation</label>
                  <input
                    id="abreviation"
                    name="abreviation"
                    type="text"
                    // autoComplete="email"
                    required
                    value={codeJournale.abreviation}
                    onChange={handleInputChange}
                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Entrez l'abréviation"
                  />
                </div>
      </Modal>

      {/* Modal de modification */}
      <Modal title={(<div className='text-center'><span className='text-lg'>Modification d'un code journal</span></div>)}
       open={openModalMod} onOk={handleOkMod} confirmLoading={confirmLoadingMod} onCancel={handleCancelMod}footer={(
              <div className="flex justify-center space-x-4">
                <button 
                  onClick={handleCancelMod} 
                  className="px-6 py-2 bg-gray-200 text-black rounded hover:bg-gray-300"
                >
                  Annuler
                </button>
                <button 
                  onClick={handleOkMod} 
                  className="px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  disabled={confirmLoadingMod}
                >
                  {confirmLoadingMod ? 'En cours...' : 'Enregistrer'}
                </button>
              </div>
            )}
          >
                <div className='space-y-2 mt-8 mb-4 hidden'>
                  <label className="font-semibold">Id</label>
                  <input
                    id="id"
                    name="id"
                    type="text"
                    required
                    value={codeJournaleMod.id}
                    onChange={handleInputChangeMod}
                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Entrez la libellé"
                  />
                </div>
                <div className='space-y-2 mt-8 mb-4'>
                  <label className="font-semibold">Libellé</label>
                  <input
                    id="libelle"
                    name="libelle"
                    type="text"
                    // autoComplete="email"
                    required
                    value={codeJournaleMod.libelle}
                    onChange={handleInputChangeMod}
                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Entrez la libellé"
                  />
                </div>

                <div className='space-y-2 mt-3 mb-8'>
                  <label className="font-semibold">Abréviation</label>
                  <input
                    id="abreviation"
                    name="abreviation"
                    type="text"
                    required
                    value={codeJournaleMod.abreviation}
                    onChange={handleInputChangeMod}
                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Entrez l'abréviation"
                  />
                </div>
      </Modal>
      
    </div>
  )
}

export default CodeJournale

