import React, { useEffect, useState } from 'react'
import SideBarKaonty from '../../../Components/Utilisateur/SideBar'
import Header from '../../../Components/Utilisateur/Header'
import { useNavigate, useParams } from 'react-router-dom'
import {LeftCircleOutlined} from '@ant-design/icons'
import {Modal} from 'antd'
import { Button, message, Space } from 'antd';
import axios from 'axios'
import { api } from '../../../api'

const ChoixMois = () => {
  const user_id = sessionStorage.getItem('admin_id');

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  const [messageApi, contextHolder] = message.useMessage();

  const [oldBudgetAnnuel, setOldBudgetAnnuel] = useState([])

  const [budgetAnnuel, setBudgetAnnuel] = useState({
    group21: 0,
    group60: 0,
    group61: 0,
    group62: 0,
    group63: 0,
    group64: 0,
    group65: 0,
    group66: 0
  })

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBudgetAnnuel({
      ...budgetAnnuel,
      [name]: value,
    });
  
  }

  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Enregistrement des budgets annuels réussie.',
    });
  };

  const successModification = () => {
    messageApi.open({
      type: 'success',
      content: 'Modification des budgets annuels réussie.',
    });
  };

  const showModal = () => {
    setOpen(true);
    getBudgetAnnuel()
  };

  const handleOk = () => {
    setConfirmLoading(true);
    ajoutBudgetAnnuel();
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleOkMod = () => {
    setConfirmLoading(true);
    modBudgetAnnuel();
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    // console.log('Clicked cancel button');
    setOpen(false);
  };

  const navigate = useNavigate()
  const {annee} = useParams()

  const revenir = () => {
    navigate('/utilisateur/anneeFiscale')
  }

  const choixMois = (mois) => {
    navigate('/utilisateur/anneeFiscale/' + annee + '/' + mois)
  }

  const toBilanAnnuel = () => {
    navigate('/utilisateur/bilanAnnuel/' + annee )
  }

  const cards = [
    'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
    'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
  ];

  const [data, setData] = useState([]);

  // L'information sur l'entité d'un utilisateur s'il existe
  const getBudgetAnnuel = async () => {
    try {
      const response = await axios.get(api + `/api/getBudgetAnnuel/${user_id}/${annee}`);
      setBudgetAnnuel(response.data[0]);
      setData(response.data)
      console.log(response.data);

    } catch (error) {
      console.error('Error fetching entity:', error);
    }
  };

  // Ajouter une entité
  const ajoutBudgetAnnuel = () => {
    // if (entiteInfo.nom === '' || entiteInfo.description === '' || entiteInfo.dateCreation === '' || entiteInfo.email === '' || entiteInfo.contact === '' || entiteInfo.adresse === '' || entiteInfo.nif === '' || entiteInfo.stat === '' || entiteInfo.rcs === '')
    // {
    //   infoIncomplete()
    // }
      
      const formData = new FormData();
      formData.append('group21', budgetAnnuel.group21);
      formData.append('group60', budgetAnnuel.group60);
      formData.append('group61', budgetAnnuel.group61);
      formData.append('group62', budgetAnnuel.group62);
      formData.append('group63', budgetAnnuel.group63);
      formData.append('group64', budgetAnnuel.group64);
      formData.append('group65', budgetAnnuel.group65);
      formData.append('group66', budgetAnnuel.group66);
      formData.append('annee', annee);
      formData.append('user_id', user_id);
      
      try {
        axios.post(api + '/api/ajoutBudgetAnnuel', formData)
            .then(res => {
                getBudgetAnnuel();

                success()
                // setEntiteInfo({
                //   ...entiteInfo,
                //   nom: '', description: '', activite:'', dateCreation:'', adresse:'', contact:'', email:'', nif:'', stat:'', rcs:''
                // });
                
            })
            .catch(err => {
                // Gérer les erreurs de la requête
                if (err.response) {
                    // Le serveur a répondu avec un code de statut différent de 2xx
                    console.error('Erreur réponse serveur:', err.response.data);
                } else if (err.request) {
                    // La requête a été faite, mais aucune réponse reçue
                    console.error('Aucune réponse reçue:', err.request);
                } else {
                    // Autre erreur lors de la configuration de la requête
                    console.error('Erreur lors de la requête:', err.message);
                }
            });
      } catch (error) {
          // Gérer les erreurs inattendues dans le bloc try
          console.error('Erreur inattendue:', error);
      }

    
  } 

  // Modification des budgets annuels
  const modBudgetAnnuel = () => {
    // if (oldEntiteInfo.nom === '' || oldEntiteInfo.description === '' || oldEntiteInfo.dateCreation === '' || oldEntiteInfo.email === '' || oldEntiteInfo.contact === '' || oldEntiteInfo.adresse === '' || oldEntiteInfo.nif === '' || oldEntiteInfo.stat === '' || oldEntiteInfo.rcs === '')
    // {
    //   infoIncomplete()
    // }
      
      const formData = new FormData();
      formData.append('group21', budgetAnnuel.group21);
      formData.append('group60', budgetAnnuel.group60);
      formData.append('group61', budgetAnnuel.group61);
      formData.append('group62', budgetAnnuel.group62);
      formData.append('group63', budgetAnnuel.group63);
      formData.append('group64', budgetAnnuel.group64);
      formData.append('group65', budgetAnnuel.group65);
      formData.append('group66', budgetAnnuel.group66);
      formData.append('annee', annee);
      formData.append('user_id', user_id);
      
      try {
        axios.post(api + '/api/modBudgetAnnuel/' + budgetAnnuel.id, formData)
            .then(res => {
                getBudgetAnnuel();

                successModification()
                
            })
            .catch(err => {
                // Gérer les erreurs de la requête
                if (err.response) {
                    // Le serveur a répondu avec un code de statut différent de 2xx
                    console.error('Erreur réponse serveur:', err.response.data);
                } else if (err.request) {
                    // La requête a été faite, mais aucune réponse reçue
                    console.error('Aucune réponse reçue:', err.request);
                } else {
                    // Autre erreur lors de la configuration de la requête
                    console.error('Erreur lors de la requête:', err.message);
                }
            });
      } catch (error) {
          // Gérer les erreurs inattendues dans le bloc try
          console.error('Erreur inattendue:', error);
      }

    
  } 

  useEffect(() => {
    getBudgetAnnuel();
  }, []);

  return (
    <div className='bg flex flex-row'>
      {contextHolder}
      <div>
        <SideBarKaonty />
      </div>
      <div className='bg w-full' style={{ background: '#F9F9FC', height: '100vh' }}>
        <div>
            <Header titre={'Année fiscale'}/>
          </div>
          {/* <div className='bg pl-8 pr-7 flex gap-36 py-7'>
            <button onClick={revenir}>Revenir</button>
            <p>Année : {annee}</p>
          </div> */}
          <div className='pl-8 pr-7 py-7'>
            <div className='flex justify-between'>
              <div className='flex space-x-3'>
                <span onClick={revenir}><LeftCircleOutlined style={{ fontSize: '25px', color: '#0071BD' }} className='c cursor-pointer'/></span>
                <p className='f font-semibold'>Veuillez sélectionner un mois :</p>
              </div>
              <div className='flex justify-between space-x-6'>
                <div>
                  <button className='px-2 py-1 font-semibold hover:bg-primary hover:text-white duration-300 border-primary rounded-lg border-2' onClick={showModal}>Budget annuel</button>
                </div>
                <div>
                  <button className='px-2 py-1 font-semibold hover:bg-primary hover:text-white duration-300 border-primary rounded-lg border-2' onClick={toBilanAnnuel}>Bilan annuel</button>
                </div>
                <div className='text-center'>
                  <span className='font-bold text-3xl'>{annee}</span>
                </div>
              </div>
            </div>
            <div>

            {/* Listes des mois */}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-x-10 gap-y-12 py-4 mt-10">
              {/* {cards.map((card, index) => (
                <div onClick={() => choixMois(card)} key={index} className="bg-white shadow-md rounded-lg border-2 border-primary p-4 text-center cursor-pointer">
                  <p className="font-semibold text-lg">{card}</p>
                </div>
              ))} */}
                <div onClick={() => choixMois(1)} className="bg-white shadow-md rounded-lg border-2 border-primary p-4 text-center cursor-pointer">
                  <p className="font-semibold text-lg">Janvier</p>
                </div>
                <div onClick={() => choixMois(2)} className="bg-white shadow-md rounded-lg border-2 border-primary p-4 text-center cursor-pointer">
                  <p className="font-semibold text-lg">Février</p>
                </div>
                <div onClick={() => choixMois(3)} className="bg-white shadow-md rounded-lg border-2 border-primary p-4 text-center cursor-pointer">
                  <p className="font-semibold text-lg">Mars</p>
                </div>
                <div onClick={() => choixMois(4)} className="bg-white shadow-md rounded-lg border-2 border-primary p-4 text-center cursor-pointer">
                  <p className="font-semibold text-lg">Avril</p>
                </div>
                <div onClick={() => choixMois(5)} className="bg-white shadow-md rounded-lg border-2 border-primary p-4 text-center cursor-pointer">
                  <p className="font-semibold text-lg">Mai</p>
                </div>
                <div onClick={() => choixMois(6)} className="bg-white shadow-md rounded-lg border-2 border-primary p-4 text-center cursor-pointer">
                  <p className="font-semibold text-lg">Juin</p>
                </div>
                <div onClick={() => choixMois(7)} className="bg-white shadow-md rounded-lg border-2 border-primary p-4 text-center cursor-pointer">
                  <p className="font-semibold text-lg">Juillet</p>
                </div>
                <div onClick={() => choixMois(8)} className="bg-white shadow-md rounded-lg border-2 border-primary p-4 text-center cursor-pointer">
                  <p className="font-semibold text-lg">Août</p>
                </div>
                <div onClick={() => choixMois(9)} className="bg-white shadow-md rounded-lg border-2 border-primary p-4 text-center cursor-pointer">
                  <p className="font-semibold text-lg">Septembre</p>
                </div>
                <div onClick={() => choixMois(10)} className="bg-white shadow-md rounded-lg border-2 border-primary p-4 text-center cursor-pointer">
                  <p className="font-semibold text-lg">Octobre</p>
                </div>
                <div onClick={() => choixMois(11)} className="bg-white shadow-md rounded-lg border-2 border-primary p-4 text-center cursor-pointer">
                  <p className="font-semibold text-lg">Novembre</p>
                </div>
                <div onClick={() => choixMois(12)} className="bg-white shadow-md rounded-lg border-2 border-primary p-4 text-center cursor-pointer">
                  <p className="font-semibold text-lg">Décembre</p>
                </div>
            </div>

            </div>
          </div>
      </div>

      {/* Modal d'ajout des info sur l'entité */}
      <Modal
        title= {(<div className='text-center'><span className='text-lg'>Budget annuel</span></div>)}
        width={600} open={open} onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel} footer={(
          <div className="flex justify-center space-x-12">
            <button 
              onClick={handleCancel} 
              className="px-6 py-2 bg-gray-200 text-black rounded hover:bg-gray-300"
            >
              Annuler
            </button>
            {
              data.length === 0 ?
              <button
                onClick={handleOk}
                className="px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                disabled={confirmLoading}
              >
                {confirmLoading ? 'En cours...' : 'Ajouter'}
              </button>
            : 
              <button
                onClick={handleOkMod}
                className="px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                disabled={confirmLoading}
              >
                {confirmLoading ? 'En cours...' : 'Modifier'}
              </button>
            }
          </div>
        )}
      >
            <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-3 gap-x-8 py-1 mt-1'>
                  
                
                <div className='grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-3 gap-x-8 py-1 mt-1'>

                    <div className='space-y-2'>
                        <label className="font-semibold">Groupement 21</label>
                        <input id="group21" name="group21"  type="number" required value={budgetAnnuel?.group21} onChange={handleInputChange} className="appearance-none relative block w-full px-2 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder=""/>
                    </div>     
                    <div className='space-y-2'>
                        <label className="font-semibold">Groupement 60</label>
                        <input id="group60" name="group60"  type="number" required value={budgetAnnuel?.group60} onChange={handleInputChange} className="appearance-none relative block w-full px-2 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder=""/>
                    </div>    
                    <div className='space-y-2'>
                        <label className="font-semibold">Groupement 61</label>
                        <input id="group61" name="group61"  type="number" required value={budgetAnnuel?.group61} onChange={handleInputChange} className="appearance-none relative block w-full px-2 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder=""/>
                    </div>    
                    <div className='space-y-2'>
                        <label className="font-semibold">Groupement 62</label>
                        <input id="group62" name="group62"  type="number" required value={budgetAnnuel?.group62} onChange={handleInputChange} className="appearance-none relative block w-full px-2 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder=""/>
                    </div>    
                    <div className='space-y-2'>
                        <label className="font-semibold">Groupement 63</label>
                        <input id="group63" name="group63"  type="number" required value={budgetAnnuel?.group63} onChange={handleInputChange} className="appearance-none relative block w-full px-2 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder=""/>
                    </div>    
                    <div className='space-y-2'>
                        <label className="font-semibold">Groupement 64</label>
                        <input id="group64" name="group64"  type="number" required value={budgetAnnuel?.group64} onChange={handleInputChange} className="appearance-none relative block w-full px-2 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder=""/>
                    </div>    
                    <div className='space-y-2'>
                        <label className="font-semibold">Groupement 65</label>
                        <input id="group65" name="group65"  type="number" required value={budgetAnnuel?.group65} onChange={handleInputChange} className="appearance-none relative block w-full px-2 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder=""/>
                    </div>    
                    <div className='space-y-2'>
                        <label className="font-semibold">Groupement 66</label>
                        <input id="group66" name="group66"  type="number" required value={budgetAnnuel?.group66} onChange={handleInputChange} className="appearance-none relative block w-full px-2 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder=""/>
                    </div>    

                </div>
                
            </div>
      </Modal>
      
      
    </div>
  )
}

export default ChoixMois

