import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Logo from '../../Assets/Logo.png';
import { LeftCircleOutlined } from '@ant-design/icons';
import { api } from '../../api';
import {Modal} from 'antd'
import {EditOutlined, DeleteOutlined, ExclamationCircleFilled, CheckCircleFilled} from '@ant-design/icons'

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    setLoading(true); // Activer l'indicateur de chargement
    setError(''); // Réinitialiser les erreurs

    try {

      if (email == '' && password == '') {
        setError('Veuillez saisir votre adresse e-mail et mot de passe.');
      }

      else if (email == '') {
        setError('Veuillez saisir votre adresse e-mail.');
      }

      else if (password == '') {
        setError('Veuillez saisir votre mot de passe.');
      }

      else {
        const response = await axios.post(api + '/api/connexion', {
          email,
          password
        });
  
        // Vérifier la réponse et gérer la redirection selon le rôle
        const role  = response.data.user.role;
        const demandes = response.data.user.demandes;
        const abonnements = response.data.user.abonnements;
        if (role === 'admin') {

          // navigate('/Admin/');

          // Rehefa tsy manana demande 'EN ATTENTE'
          if (demandes.length === 0)
          {

            navigate('/abonnement')

          } else {

            // console.log('Il y a au moins une demande')
            if (abonnements.length === 0)
            {
              // Raha tsy manana abonnements mihintsy nefa manana demande
              showModalNullAbo()
            } else {
              // Raha manana abonnements
              navigate('/admin/')
            }

          }

        } else if (role === 'utilisateur') {

          const res = await axios.post(api + '/api/connexionUtilisateur/' + response.data.user.user_id)

          const dem = res.data.user.demandes;
          const abo = res.data.user.abonnements;

           // Rehefa tsy manana demande 'EN ATTENTE'
           if (dem.length === 0)
            {
  
              // navigate('/abonnement')
              // Modal avec message pas d'abonnement
  
            } else {
  
              // console.log('Il y a au moins une demande')
              if (abo.length === 0)
              {
                // Raha tsy manana abonnements mihintsy nefa manana demande
                showModalNullAbo()
              } else {
                // Raha manana abonnements
                navigate('/Utilisateur/')
              }
  
            }

          // console.log(response.data.user.user_id)

          // navigate('/Utilisateur/');

        } else if (role === 'superAdmin') {

          navigate('/superAdmin/');

        }
  
        console.log(response.data)
  
        const token = response.data.token;
        const user_id = response.data.user.id;
        const admin_id = response.data.user.user_id;
        const nomUser = response.data.user.nom;
        const prenomUser = response.data.user.prenom;
        const photoUser = response.data.user.photo;
        sessionStorage.setItem('token', token);
        sessionStorage.setItem('user_id', user_id);
        sessionStorage.setItem('admin_id', admin_id);
        sessionStorage.setItem('nomUser', nomUser);
        sessionStorage.setItem('prenomUser', prenomUser);
        sessionStorage.setItem('photoUser', photoUser);
  
        // Réinitialiser les champs après connexion réussie
        setEmail('');
        setPassword('');
      }

      
    } catch (err) {
      // Gérer les erreurs de connexion
      setError('E-mail ou mot de passe incorrect, veuillez ressayer.');
      console.log(err)
    } finally {
      setLoading(false); // Désactiver l'indicateur de chargement
    }
  };

  const Inscription = () => {
    navigate('/verification');
  };

  const forgotPassword = () => {
    navigate('/verificationEmail')
  }

  const revenir = () => {
    navigate('/');
  };

  const { confirm } = Modal;

  // Modal d'affichage pas d'abonnements
  const showModalNullAbo = () => {
    confirm({
      // title: 'Votre demande d\'abonnement est en cours de traitement, veuillez attendre un moment s\'il vous plaît!',
      title: 'Votre abonnement au plateforme est expiré. Si vous avez envoyé une demande d\'abonnement, veuillez attendre un moment s\'il vous plaît sinon, veuillez s\'abonner pour profiter les services.',
      icon: <ExclamationCircleFilled />,
      content: (
        <div>
          {/* <p>Code: {journal.id}</p>
          <p>Description: {journal.abreviation}</p>
          <p>Description: {journal.libelle}</p> */}
          {/* <p>Contenu du modal</p> */}
        </div>
      ),
      okText: 'S\'abonner', // Texte personnalisé pour le bouton OK
      cancelText: 'Retour',
      okButtonProps: {
        style: { backgroundColor: 'primary', borderColor: 'primary', color: 'white' }, // Bouton OK en rouge
      },
      onOk() {
        
        navigate('/abonnement')
        
      },
      onCancel() {
        // alert('Annulé')
      },
    });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-3">
        <div>
          <span onClick={revenir}>
            <LeftCircleOutlined style={{ fontSize: '25px', color: '#0071BD' }} className="cursor-pointer" />
          </span>
        </div>
        <div className="flex items-center justify-center">
          <img src={Logo} className="w-48 md:w-56" alt="Logo" />
        </div>
        <div>
          <h2 className="mt-3 text-center text-3xl font-extrabold text-gray-900">Authentifiez-vous</h2>
        </div>
        {error && (
          <div className="text-red-600 text-center font-semibold">
            {error}
          </div>
        )}
        <form className="mt-10 space-y-2" onSubmit={(e) => e.preventDefault()}>
          <div className="rounded-md shadow-sm space-y-3">
            <div className="space-y-2">
              <label htmlFor="email" className="font-semibold">Adresse e-mail</label>
              <input
                id="email"
                name="email"
                type="email"
                // autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Votre e-mail"
              />
            </div>
            <div className="space-y-2">
              <label htmlFor="password" className="font-semibold">Mot de passe</label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="********
                "
              />
            </div>
          </div>
          
          <div className=''>
            <button
              type="button"
              onClick={handleLogin}
              disabled={loading}
              className={`group mt-4 relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              {loading ? 'Connexion en cours...' : 'Se connecter'}
            </button>
          </div>
          <div className="flex items-center justify-between">
            <div className="text-sm" onClick={forgotPassword}>
              <a href="#" className="font-medium text-primary hover:underline">
                Mot de passe oublié ?
              </a>
            </div>
            <div className="text-center">
              <p>Pas de compte ? <span className="text-primary hover:underline cursor-pointer font-semibold" onClick={Inscription}>Inscrivez-vous</span></p>
            </div>
          </div>
          {/* <div className="text-center">
            <p>Pas de compte ? <span className="text-primary cursor-pointer font-semibold" onClick={Inscription}>Inscrivez-vous</span></p>
          </div> */}
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
