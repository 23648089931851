import React from 'react'
import SideBarKaonty from '../../../Components/Utilisateur/SideBar'
import Header from '../../../Components/Utilisateur/Header'
import { useParams } from 'react-router-dom'
import LineChart from '../Charts/LineChartDash'

const BilanAnnuel = () => {
  const {annee} = useParams()

  return (
    <div className='bg flex flex-row' style={{ height: '100vh' }}>
      <div>
        <SideBarKaonty />
      </div>
      <div className='bg w-full' style={{ background: '#F9F9FC' }}>
        <div>
          <Header titre={'Bilan annuel'}/>
        </div>
        <div className='pl-1 py-4'>
          
        <div className='mt-1 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 py-4 h-[200px] mx-2'>

          <div className=''>
            <div className='flex text-center justify-center space-x-5'>
              <h1 className='text-center font-semibold text-lg'>EVOLUTION CA-DEPENSES-RESULTAT : <span className='font-bold ml-2 text-xl'>{annee}</span></h1>  
            </div>
            {/* Affichage du PieChart */}
            <div className="">
              <LineChart  annee={annee}/>
            </div>
          </div>

          </div>

        </div>
      </div>
      
    </div>
  )
}

export default BilanAnnuel