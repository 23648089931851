import React from 'react'
import img4 from '../Assets/img4.jpg'
import suiviDepense from '../Assets/suiviDepense.jpg'
import gestion from '../Assets/gestion.jpg'
import rapport from '../Assets/rapport.jpg'
import {motion} from "framer-motion"
import {fadeIn} from '../variant'

const Fonctionnalite = () => {
  return (
    <div className='my-24 md:px-24 px-4 max-w-screen-2xl mx-auto' id='fonctionnalite'>
      <div className='flex flex-col lg:flex-row justify-between items-start gap-10'>
        <motion.div
        variants={fadeIn("right", 0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.7}}
        className='l lg:w-1/4 md:mt-14'>
          <h3 className='text-2xl text-primary font-bold mb-3'>Pourquoi nous sommes meilleurs que les autres </h3>
          <p className='text-lg text-justify'>Notre application de gestion de comptabilité de base offre une simplicité et une efficacité inégalées. Une application qui répond spécifiquement aux besoins des entreprises qui cherchent une solution simple, performante et accessible</p>
        </motion.div>

        <motion.div
        variants={fadeIn("up", 0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.7}}
        className='w-full lg:w-3/4'>
          <div className='grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 items-start md:gap-12 gap-8'>

            <div className='bg-[rgba(255, 255, 255, 0.04)] rounded-[35px] h-96 shadow-2xl p-8 items-center flex justify-center hover:translate-y-4 transition-all duration-300 cursor-pointer'>
              <div className=''>
                <img src={suiviDepense} alt="" className='w-40 ' />
                <h5 className='t text-xl font-semibold px-5 text-center mt-5'>Suivi des dépenses <br /> et revenu</h5>
              </div>
            </div>
            <div className='bg-[rgba(255, 255, 255, 0.04)] rounded-[35px] h-96 shadow-2xl p-8 items-center flex justify-center hover:translate-y-4 transition-all duration-300 cursor-pointer md:mt-12'>
              <div className=''>
                <img src={gestion} alt="" className='w-40 ' />
                <h5 className='t text-xl font-semibold px-5 text-center mt-5'>Gestion des journaux <br /> de saisie</h5>
              </div>
            </div>
            <div className='bg-[rgba(255, 255, 255, 0.04)] rounded-[35px] h-96 shadow-2xl p-8 items-center flex justify-center hover:translate-y-4 transition-all duration-300 cursor-pointer'>
              <div className=''>
                <img src={rapport} alt="" className='w-40 ' />
                <h5 className='t text-xl font-semibold px-5 text-center mt-5'>Exportation des <br /> rapports</h5>
              </div>
            </div>

          </div>
        </motion.div>
      </div>
    </div>
  )
}

export default Fonctionnalite