import React from 'react'
import Logo from "../Assets/Logo.png"

const Footer = () => {
  return (
    <div className='bg bg-white md:px-24 p-4 max-w-screen-2xl mx-auto text-black mt-8'>
      <div>
      <hr className='mt-4 mb-2'/>

        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-24'>
          <div className='flex flex-col'>
            <a href="/"><img src={Logo} alt="" className='w-32 inline-block items-center -translate-x-4' /></a>
            <p className='text-md text-justify'>Un outil logiciel conçu pour automatiser et simplifier les tâches comptables essentielles
               pour les petites et moyennes entreprises (PME), les indépendants ou les startups. 
               Elle permet de gérer efficacement les finances tout en réduisant la complexité des processus comptables.</p>
          </div>

          <div className='flex flex-col mt-4 space-y-2 lg:ml-20'>
            <span className=' text-xl font-bold'>Nos services</span>
            <a href='https://tsenavitamalagasy.com/' className='c cursor-pointer hover:text-primary text-md'>Tsena vita Malagasy</a>
            <a href='https://naturamadagascar.com/' className='c cursor-pointer hover:text-primary text-md'>Natura Madagascar</a>
            <span className='c cursor-pointer hover:text-primary'></span>
            <span className='c cursor-pointer hover:text-primary'></span>
          </div>

          <div className='flex flex-col mt-4 space-y-2 text-md'>
            <span className=' text-xl font-bold'>Adresse et contact</span>
            <span className=''>Lot VK 17A Fenomanana-Mahazoarivo</span>
            <span className=''>Antananarivo-101 , Madagascar</span>
            {/* <span className=''>Madagascar</span> */}
            <span>Téléphone : +261 81 818 21</span>
            <span>E-mail : info@haisoa.com</span>
          </div>
        </div>

        <hr className='mt-4 mb-2'/>

        {/* Copy right */}
        <div className='text-center text-md'>
          <span>Copyright  <a href="http://www.haisoa.com" className='cursor-pointer hover:text-primary text-blue-400 hover:underline'>HAISOA</a>. Tous droits réservés, conçu par <a href='http://www.haisoa.com' className='cursor-pointer hover:text-primary text-blue-400 hover:underline'>HAISOA</a></span><br />
        </div>
      </div>
    </div>
  )
}

export default Footer