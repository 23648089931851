import React, { useEffect, useState } from 'react'
import SideBarKaonty from '../../../Components/SideBarKaonty'
import Header from '../../../Components/Admin/Header'
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { FaPlusCircle } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import {EditOutlined, DeleteOutlined, ExclamationCircleFilled, FilterOutlined, LeftOutlined, RightOutlined} from '@ant-design/icons'
import {Modal} from 'antd'
import { useParams } from 'react-router-dom';
import { AutoComplete, Input } from 'antd';
import axios from 'axios';
import { api } from '../../../api';
import { Button, message, Space, Select, Spin } from 'antd';

const JournalSaisie = () => {

  const user_id = sessionStorage.getItem('user_id');
  const [loading, setLoading] = useState(true);
  const [loadSelect, setLoadSelect] = useState(false);
  const [lastPage, setLastPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [cloture, setCloture] = useState(true);  // false : Miseho ny bouton ; true: Tsy miseho ny bouton

  const { Option } = Select

  const [messageApi, contextHolder] = message.useMessage();
  const successAjout = () => {
    messageApi.open({
      type: 'success',
      content: 'Journal de saisie ajouté avec succès !',
    });
  };

  const infoIncomplete = ($choix) => {
    messageApi.open({
      type: 'info',
      content: 'Veuillez choisir un ' + $choix + ' s\'il vous plaît.',
    });
  };

  const successModification = () => {
    messageApi.open({
      type: 'success',
      content: 'Journal de saisie modifié avec succès !',
    });
    setModJournalId(null);
                  setModJournal({});
  };

  const successCloture = () => {
    messageApi.open({
      type: 'success',
      content: 'La journal de saisie est clôturé avec succès !',
    });
  };

  const successSuppression = () => {
    messageApi.open({
      type: 'success',
      content: 'Journal de saisie supprimé avec succès !',
    });
  };

  const {annee, mois} = useParams()

  // Récuperer le nom du mois
  const moisNom = [
    'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
    'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
  ];
  const moisIndex = parseInt(mois) - 1;
  const nomMois = moisNom[moisIndex] || "Mois inconnu";

  const [planComptable, setPlanComptable] = useState([])
  const [codeJournal, setCodeJournal] = useState([])

  // const data = [
  //   { id: 1, date: '26/08/24', reference: 345678, facture: 345674, journal: 'BQ', cheque: 3456767, compte: 7070, libelle: 'Carte de recharge', entree: '1 200 000 Ar', sortie: '', tiers: 'Créance', debit: '150 000 Ar', credit: ''},
  //   { id: 1, date: '26/08/24', reference: 345678, facture: 345674, journal: 'CA', cheque: 3456767, compte: 7070, libelle: 'Carte de recharge', entree: '', sortie: '450 000 Ar', tiers: 'Créance', debit: '150 000 Ar', credit: ''},
  //   { id: 1, date: '26/08/24', reference: 345678, facture: 345674, journal: 'BQ', cheque: 3456767, compte: 7070, libelle: 'Carte de recharge', entree: '200 000 Ar', sortie: '', tiers: 'Créance', debit: '', credit: '180 000 Ar'},
  //   { id: 1, date: '25/08/24', reference: 345678, facture: 345674, journal: 'CA', cheque: 3456767, compte: 7070, libelle: 'Carte de recharge', entree: '200 000 Ar', sortie: '', tiers: 'Créance', debit: '150 000 Ar', credit: ''},
  //   { id: 1, date: '25/08/24', reference: 345678, facture: 345674, journal: 'BQ', cheque: 3456767, compte: 7070, libelle: 'Carte de recharge', entree: '', sortie: '200 000 Ar', tiers: 'Créance', debit: '150 000 Ar', credit: ''},
  //   { id: 1, date: '25/08/24', reference: 345678, facture: 345674, journal: 'BQ', cheque: 3456767, compte: 7070, libelle: 'Carte de recharge', entree: '200 000 Ar', sortie: '', tiers: 'Créance', debit: '150 000 Ar', credit: ''},
  //   { id: 1, date: '24/08/24', reference: 345678, facture: 345674, journal: 'CA', cheque: 3456767, compte: 7070, libelle: 'Carte de recharge', entree: '200 000 Ar', sortie: '', tiers: 'Créance', debit: '', credit: '150 000 Ar'},
  //   { id: 1, date: '24/08/24', reference: 345678, facture: 345674, journal: 'CA', cheque: 3456767, compte: 7070, libelle: 'Carte de recharge', entree: '', sortie: '20 000 Ar', tiers: 'Créance', debit: '150 000 Ar', credit: ''},
  //   { id: 1, date: '24/08/24', reference: 345678, facture: 345674, journal: 'CA', cheque: 3456767, compte: 7070, libelle: 'Carte de recharge', entree: '200 000 Ar', sortie: '', tiers: 'Créance', debit: '', credit: '150 000 Ar'},
  //   { id: 1, date: '23/08/24', reference: 345678, facture: 345674, journal: 'BQ', cheque: 3456767, compte: 7070, libelle: 'Carte de recharge', entree: '200 000 Ar', sortie: '', tiers: 'Créance', debit: '150 000 Ar', credit: ''},
  // ];

  // Auto-completion
  const [options, setOptions] = useState([]);
  const [selectedId, setSelectedId] = useState(null);

  const [journalSaisie, setJournalSaisie] = useState({
    date: new Date().toISOString().split('T')[0],
    ref: '',
    facture: '',
    libelle: '',
    cheque: '',
    journal_id: '',
    entree: 0,
    sortie: 0,
    tiers: '',
    debit: 0,
    credit: 0,
    cloture: '',
    pcg_id: '',
    user_id: user_id
  })

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    getAllPlanComptableGlobalPrivee();
    getJournalSaisieMoisAnnee()
    getAllCodeJournalGlobalPrivee()
  }, [currentPage]);

  const getJournalSaisieMoisAnnee = async () => {
    try {
      const res = await axios.get(api + '/api/allJournalSaisieMoisAnnee/' + mois + '/' + annee + '/' + user_id + `?page=${currentPage}` );
          setData(res.data.data);
          // Si le data n'est pas vide
          if (res.data.data.length > 0)
          {
              if (res.data.data[0].cloture == 0)
              {
                setCloture(false)
              }
    
              else {
                setCloture(true)
              }
          }
      // console.log(res.data.data[0].cloture)
      // console.log(res.data);
      setLastPage(res.data.last_page)
      setLoading(false)
    } catch (err) {
      console.error('Erreur lors de la requête:', err.response || err.request || err.message);
    }
  };

  // Tous les plans comptables globaux et privées
  const getAllPlanComptableGlobalPrivee = async () => {
    try {
      const res = await axios.get(`${api}/api/allPlanComptableGlobalPriveeTous/${user_id}`);
      setPlanComptable(res.data);
      // console.log(res.data);
    } catch (err) {
      console.error('Erreur lors de la requête:', err.response || err.request || err.message);
    }
  };

  // Tous les codes journals
  const getAllCodeJournalGlobalPrivee = async () => {
    try {
      const res = await axios.get(`${api}/api/allCodeJournalGlobalPrivee/${user_id}`);
      setCodeJournal(res.data);
      console.log(res.data);
    } catch (err) {
      console.error('Erreur lors de la requête:', err.response || err.request || err.message);
    }
  };

  // Selectionner un code journal
  const choixCodeJournal = (value) => {
    // Récupérer l'ID du plan comptable sélectionné
    // setSelectedId(option.id);
    setJournalSaisie({
      ...journalSaisie,
      journal_id: value,
    });
    // console.log('ID sélectionné :', value);
  };

  // CHoix de tiers pendant l'ajout
  const choixTiers = (value) => {
    // Récupérer l'ID du plan comptable sélectionné
    // setSelectedId(option.id);
    setJournalSaisie({
      ...journalSaisie,
      tiers: value,
    });
    // console.log('ID sélectionné :', value);
  };

  const choixTiersMod = (value) => {
    // Récupérer l'ID du plan comptable sélectionné
    // setSelectedId(option.id);
    setModJournal({
      ...modJournal,
      tiers: value,
    });
    // console.log('ID sélectionné :', value);
  };

  const choixCodeJournalMod = (value) => {
    // Récupérer l'ID du plan comptable sélectionné
    // setSelectedId(option.id);
    setModJournal({
      ...modJournal,
      journal_id: value,
    });
    // console.log('ID sélectionné :', value);
  };

  // Recherche des numeros de compte
  const handleSearch = (value) => {
    // Filtrer les suggestions par numCompte
    const filteredOptions = planComptable
      .filter(pc => pc.numCompte.toString().includes(value)) // Convertir en chaîne
      .map(pc => ({
        value: pc.numCompte, // Ce qui sera affiché dans l'auto-complete
        label: `${pc.numCompte} - ${pc.libelle}`, // Libellé affiché
        id: pc.id // L'ID correspondant au compte
      }));

    setOptions(filteredOptions);
  };

  // Selectionner un numéro de compte
  const handleSelect = (value, option) => {
    // Récupérer l'ID du plan comptable sélectionné
    // setSelectedId(option.id);
    setJournalSaisie({
      ...journalSaisie,
      pcg_id: option.id,
    });
    // console.log('ID sélectionné :', option.id);
  };

  const handleSelectMod = (value, option) => {
    // Récupérer l'ID du plan comptable sélectionné
    // setSelectedId(option.id);
    setModJournal({
      ...modJournal,
      pcg_id: option.id,
    });
    // console.log('ID sélectionné :', option.id);
  };

  // Fin d'auto-completion

  // Contrôle des champs d'entrée et sortie
  const [entree, setEntree] = useState(''); // État pour l'entrée
  const [sortie, setSortie] = useState(''); // État pour la sortie

  const handleEntreeChange = (e) => {
    const value = e.target.value;
  
    // Mettre à jour l'entrée et ajuster la sortie selon la valeur
    setJournalSaisie((prevState) => ({
      ...prevState,
      entree: value,
      sortie: value ? 0 : '', // Si 'entree' a une valeur, 'sortie' devient 0, sinon permet la saisie
    }));
  };

  const handleSortieChange = (e) => {
    const value = e.target.value;
  
    // Mettre à jour l'entrée et ajuster la sortie selon la valeur
    setJournalSaisie((prevState) => ({
      ...prevState,
      sortie: value,
      entree: value ? 0 : '', // Si 'entree' a une valeur, 'sortie' devient 0, sinon permet la saisie
    }));
  };

  const handleEntreeChangeMod = (e) => {
    const value = e.target.value;
  
    // Mettre à jour l'entrée et ajuster la sortie selon la valeur
    setModJournal((prevState) => ({
      ...prevState,
      entree: value,
      sortie: value ? 0 : '', // Si 'entree' a une valeur, 'sortie' devient 0, sinon permet la saisie
    }));
  };

  const handleSortieChangeMod = (e) => {
    const value = e.target.value;
  
    // Mettre à jour l'entrée et ajuster la sortie selon la valeur
    setModJournal((prevState) => ({
      ...prevState,
      sortie: value,
      entree: value ? 0 : '', // Si 'entree' a une valeur, 'sortie' devient 0, sinon permet la saisie
    }));
  };
  
    // Contrôle des champs du débit et credit
  const handleDebitChange = (e) => {
    const value = e.target.value;
  
    // Mettre à jour l'entrée et ajuster la sortie selon la valeur
    setJournalSaisie((prevState) => ({
      ...prevState,
      debit: value,
      credit: value ? 0 : '', // Si 'entree' a une valeur, 'sortie' devient 0, sinon permet la saisie
    }));
  };

  const handleCreditChange = (e) => {
    const value = e.target.value;
  
    // Mettre à jour l'entrée et ajuster la sortie selon la valeur
    setJournalSaisie((prevState) => ({
      ...prevState,
      credit: value,
      debit: value ? 0 : '', // Si 'entree' a une valeur, 'sortie' devient 0, sinon permet la saisie
    }));
  };

   // Contrôle des champs du débit et credit
   const handleDebitChangeMod = (e) => {
    const value = e.target.value;
  
    // Mettre à jour l'entrée et ajuster la sortie selon la valeur
    setModJournal((prevState) => ({
      ...prevState,
      debit: value,
      credit: value ? 0 : '', // Si 'entree' a une valeur, 'sortie' devient 0, sinon permet la saisie
    }));
  };

  const handleCreditChangeMod = (e) => {
    const value = e.target.value;
  
    // Mettre à jour l'entrée et ajuster la sortie selon la valeur
    setModJournal((prevState) => ({
      ...prevState,
      credit: value,
      debit: value ? 0 : '', // Si 'entree' a une valeur, 'sortie' devient 0, sinon permet la saisie
    }));
  };

  const { confirm } = Modal;
  
  // Modal de suppression
  const showConfirmDelete = (journal) => {
    confirm({
      title: 'Voulez-vous vraiment supprimer ce journal de saisie ?',
      icon: <ExclamationCircleFilled />,
      okText: 'Oui, supprimer', // Texte personnalisé pour le bouton OK
      cancelText: 'Non, annuler',
      okButtonProps: {
        style: { backgroundColor: 'red', borderColor: 'red', color: 'white' }, // Bouton OK en rouge
      },
      onOk() {
        // Fonction pour supprimer un journal de saisie
        supJournaSaisie(journal.id)
      },
      onCancel() {
        // alert('Annulé')
      },
    });
  };

  // Suppression d'un journal de saisie
  const supJournaSaisie = async (id) => {
    try {
      axios.post(api + `/api/supJournalSaisie/${id}`)
          .then(res => {
              successSuppression();
              // Gérer la réponse en cas de succès
              // console.log('Suppression du code journal réussie:', res.data);
              getJournalSaisieMoisAnnee()()
              
          })
          .catch(err => {
              // Gérer les erreurs de la requête
              if (err.response) {
                  // Le serveur a répondu avec un code de statut différent de 2xx
                  console.error('Erreur réponse serveur:', err.response.data);
              } else if (err.request) {
                  // La requête a été faite, mais aucune réponse reçue
                  console.error('Aucune réponse reçue:', err.request);
              } else {
                  // Autre erreur lors de la configuration de la requête
                  console.error('Erreur lors de la requête:', err.message);
              }
          });
    } catch (error) {
        // Gérer les erreurs inattendues dans le bloc try
        console.error('Erreur inattendue:', error);
    }
  };

  // Modal de confirmation d'ajout
  const showConfirmAjout = () => {
    confirm({
      title: 'Voulez-vous vraiment ajouter ce journal de saisie ?',
      icon: <ExclamationCircleFilled />,
      content: (
        <div>
          {/* <p>Code: {journal.id}</p>
          <p>Description: {journal.abreviation}</p>
          <p>Description: {journal.libelle}</p> */}
          {/* <p>Contenu du modal</p> */}
        </div>
      ),
      okText: 'Oui, ajouter', // Texte personnalisé pour le bouton OK
      cancelText: 'Non, annuler',
      okButtonProps: {
        style: { backgroundColor: 'primary', borderColor: 'primary', color: 'white' }, // Bouton OK en rouge
      },
      onOk() {

        if (journalSaisie.journal_id === '')
        {
          // alert('Veuillez choisir un code journal !')
          infoIncomplete('code journal')
        }
        else if (journalSaisie.pcg_id === '')
        {
          // alert('Veuillez choisir un plan comptable !')
          infoIncomplete('numéro de compte')
        }
        else {

              // console.log(journalSaisie);
            try {
              const form = new FormData()
              form.append("date", journalSaisie.date)
              form.append("ref", journalSaisie.ref)
              form.append("facture", journalSaisie.facture)
              form.append("libelle", journalSaisie.libelle)
              form.append("cheque", journalSaisie.cheque)
              form.append("journal_id", journalSaisie.journal_id)
              form.append("entree", journalSaisie.entree)
              form.append("sortie", journalSaisie.sortie)
              form.append("tiers", journalSaisie.tiers)
              form.append("debit", journalSaisie.debit)
              form.append("credit", journalSaisie.credit)
              form.append("cloture", 0)
              form.append("pcg_id", journalSaisie.pcg_id)
              form.append("user_id", journalSaisie.user_id)

              axios.post(api + "/api/ajoutJournalSaisie", form)
                  .then(res => {
                      console.log(res);
                      setJournalSaisie({
                        ...journalSaisie,
                        ref: '', facture: '', libelle: '', cheque: '', journal_id: '', entree: 0, sortie: 0, tiers: '', debit: 0, credit: 0, cloture: '', pcg_id: '',
                      });
                      successAjout();
                      getJournalSaisieMoisAnnee()
                      // Feedback succes
                  })
                  .catch(err => {
                      console.error(err);
                  })
          } catch (error) {
              console.error(error)
          }

        }
        
      },
      onCancel() {
        // alert('Annulé')
      },
    });
  };

  // Modal de confirmation de modification
  const showConfirmMod = () => {
    confirm({
      title: 'Voulez-vous vraiment modifier ce journal de saisie ?',
      icon: <ExclamationCircleFilled />,
      content: (
        <div>
          {/* <p>Code: {journal.id}</p>
          <p>Description: {journal.abreviation}</p>
          <p>Description: {journal.libelle}</p> */}
          {/* <p>Contenu du modal</p> */}
        </div>
      ),
      okText: 'Oui, modifier', // Texte personnalisé pour le bouton OK
      cancelText: 'Non, annuler',
      okButtonProps: {
        style: { backgroundColor: 'primary', borderColor: 'primary', color: 'white' }, // Bouton OK en rouge
      },
      onOk() {
        // console.log('Supprimer');
        // alert('Modifié')
        // console.log(modJournal)
        try {
          const form = new FormData()
          form.append("date", modJournal.date)
          form.append("ref", modJournal.ref)
          form.append("facture", modJournal.facture)
          form.append("libelle", modJournal.libelle)
          form.append("cheque", modJournal.cheque)
          form.append("journal_id", modJournal.journal_id)
          form.append("entree", modJournal.entree)
          form.append("sortie", modJournal.sortie)
          form.append("tiers", modJournal.tiers)
          form.append("debit", modJournal.debit)
          form.append("credit", modJournal.credit)
          form.append("pcg_id", modJournal.pcg_id)
          form.append("user_id", modJournal.user_id)

          // const id = modJournal.id

          axios.post(api + '/api/modJournalSaisie/' + modJournal.id, form)
              .then(res => {
                  // setModJournalId(null);
                  // setModJournal({});
                  // console.log(res.data);
                  // setModJournal({
                  //   ...modJournal,
                  //   ref: '', facture: '', libelle: '', cheque: '', journal_id: '', entree: 0, sortie: 0, tiers: '', debit: 0, credit: 0, cloture: '', pcg_id: '',
                  // });
                  // modJournalId = ''
                  successModification();
                  getJournalSaisieMoisAnnee()
                  
                  // Feedback succes
              })
              .catch(err => {
                  console.error(err);
              })
      } catch (error) {
          console.error(error)
      }
      },
      onCancel() {
        // alert('Annulé')
      },
    });
  };

  // Modal de confirmation de clôture
  const showConfirmCloturer = () => {
    confirm({
      title: 'Voulez-vous vraiment clôturer ce journal de saisie : ' + nomMois + ' ' + annee +  ' ?',
      icon: <ExclamationCircleFilled />,
      content: (
        <div>
          {/* <p>Code: {journal.id}</p>
          <p>Description: {journal.abreviation}</p>
          <p>Description: {journal.libelle}</p> */}
          {/* <p>Contenu du modal</p> */}
        </div>
      ),
      okText: 'Oui, clôturer', // Texte personnalisé pour le bouton OK
      cancelText: 'Non, annuler',
      okButtonProps: {
        style: { backgroundColor: 'primary', borderColor: 'primary', color: 'white' }, // Bouton OK en rouge
      },
      onOk() {
        // console.log('Supprimer');
        // alert('Modifié')
        try {
          const form = new FormData()
          form.append("mois", mois)
          form.append("annee", annee)
          form.append("user_id", user_id)

          axios.post(api + '/api/clotureJournalSaisie/', form)
              .then(res => {
                  
                  successCloture();
                  getJournalSaisieMoisAnnee()
                  
                  // Feedback succes
              })
              .catch(err => {
                  console.error(err);
              })
      } catch (error) {
          console.error(error)
      }
      },
      onCancel() {
        // alert('Annulé')
      },
    });
  };



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setJournalSaisie({
      ...journalSaisie,
      [name]: value,
    });
  
  }

  const handleInputModChange = (e) => {
    const { name, value } = e.target;
    setModJournal({
      ...modJournal,
      [name]: value,
    });
  
  }

  const [modJournalId, setModJournalId] = useState(null); // ID de la ligne à éditer

  const [modJournal, setModJournal] = useState({
    date: '',
    ref: '',
    facture: '',
    libelle: '',
    cheque: '',
    journal_id: '',
    entree: 0,
    sortie: 0,
    tiers: '',
    debit: 0,
    credit: 0,
    cloture: '',
    pcg_id: '',
    user_id: user_id
  })

  const handleEdit = (journal) => {
    setModJournalId(journal.id); // ID de la ligne que vous éditez
    setModJournal(journal); // Remplir les champs à éditer avec les valeurs du journal sélectionné
  };

  const handleCancelEdit = () => {
    setModJournalId(null);
    setModJournal({});
  };

  const supInput = (e) => {
    const { name } = e.target;
    setJournalSaisie({
      ...journalSaisie,
      ref: '', facture: '', libelle: '', cheque: '', journal: '', entree: 0, sortie: 0, tiers: '', debit: 0, credit: 0, cloture: '', pcg_id: '',
    });
  }

  // État pour les filtres
const [filterEtat, setFilterEtat] = useState('');
const [searchTerm, setSearchTerm] = useState('');
const [filterDate, setFilterDate] = useState(''); // État pour la date de filtre

// Gestionnaire pour mettre à jour la date de filtre
const handleDateChange = (e) => {
  setFilterDate(e.target.value);
};

// Filtrage des données selon les valeurs de filterEtat, searchTerm et filterDate
const filteredData = data.filter(journal => {
  const matchFilterEtat = filterEtat ? journal.journal.abreviation === filterEtat : true;
  const matchSearchTerm = searchTerm ? journal.libelle.toString().includes(searchTerm) : true;
  const matchFilterDate = filterDate ? journal.date === filterDate : true;
  
  return matchFilterEtat && matchSearchTerm && matchFilterDate;
});

  const formatDateToDisplay = (date) => {
    if (!date) return '';
    const [year, month, day] = date.split('-');
    return `${day}-${month}-${year}`;
  };


  return (
    <div className='bg flex flex-row' style={{ height: '100vh' }}>
       {contextHolder}
      <div>
        <SideBarKaonty />
      </div>
      <div className='bg w-full overflow-x-hidden' style={{ background: '#F9F9FC' }}>
        <div>
          <Header titre={'Journal de saisie'}/>
        </div>

        <div className='pl-8 pr-7 py-3'>
          <div className='flex justify-between text-lg'>
            <div className='flex space-x-28'>
              <div className='flex space-x-5'>
                <div>
                  <span className='font-semibold'>Année fiscale :</span>
                </div>
                <div>
                  <span className='font-semibold text-primary'>{annee}</span>
                </div>
              </div>

              <div className='flex space-x-5'>
                <div>
                  <span className='font-semibold'>Mois :</span>
                </div>
                <div>
                  <span className='font-semibold text-primary'>{nomMois}</span>
                </div>
              </div>
              <div>

              </div>
            </div>

            <div>
              {
                cloture ? (
                  <></>
                ) : (
                  <>
                    <button className='px-4 font-semibold bg-white text-primary duration-300 border-primary rounded-lg border-2 hover:text-white hover:bg-primary' onClick={showConfirmCloturer}>Clôturer</button>
                  </>
                )
              }
            </div>
          </div>

          {/* Filtre */}
          <div className='flex space-x-10 mt-3'>
            <div>
              <span className='font-semibold text-lg'>Filtre <span><FilterOutlined /></span> :</span>
            </div>
            <div>
              {/* <input type="date" className='border border-primary rounded-lg w-32 px-1' /> */}
              <input
                  type="date"
                  value={filterDate}
                  onChange={handleDateChange}
                  className="border border-primary rounded-lg w-32 px-1 cursor-pointer"
                />
            </div>
            <div>
              {/* <input type="text" placeholder='journal' className='border border-primary rounded-lg w-32 px-1 text-lg' /> */}
              {/* <select name="" id="" className='border border-primary rounded-lg px-1 text-lg'>
                <option value="">Tous les journaux</option>
                <option value="BQ">Banque</option>
                <option value="CA">Caisse</option>
                <option value="AC">Achat à crédit</option>
                <option value="VC">Vente à crédit</option>
              </select> */}
              <select value={filterEtat} onChange={(e) => setFilterEtat(e.target.value)} className='border py-[2px] px-1 border-primary rounded-md'>
                <option value="">Tous les journaux</option>
                <option value="BQ">Banque</option>
                <option value="CA">Caisse</option>
                <option value="AC">Achat à crédit</option>
                <option value="VC">Vente à crédit</option>
              </select>
            </div>
            <div>
              {/* <input type="text" placeholder='Rechercher' className='border border-primary rounded-lg w-44 px-2 text-lg' /> */}
              <input
                    type="text"
                    placeholder="Filtrer par libellé"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="py-[1px] px-1 border border-primary rounded"
                  />
            </div>
          </div>

          {/* Tableau */}
          <div className=''>
              <div className="overflow-x-auto mt-5 rounded-xl shadow-lg">
                <table className=" bg-white border w-full border-gray-200">
                  <thead>
                    <tr>
                      <td className='py-2 px-2'><input type="date" value={journalSaisie.date} onChange={handleInputChange} name="date" id='date' defaultValue={new Date().toISOString().split('T')[0]} className='border border-primary rounded w-32 px-1' /></td>
                      <td className='py-2 px-1'><input type="number" value={journalSaisie.ref} onChange={handleInputChange} name='ref' id='ref' placeholder='Référence' className='border border-primary rounded w-24 px-1' /></td>
                      <td className='py-2 px-1'><input type="number" value={journalSaisie.facture} onChange={handleInputChange} name='facture' id='facture' placeholder='Facture' className='border border-primary rounded w-24 px-1' /></td>
                      <td className='py-2 px-1'>
                        {/* <select name="journal" value={journalSaisie.journal}  onChange={choixCodeJournal} id="journal" className='border border-primary rounded w-24 px-1'>
                          {codeJournal.map((codeJournal) => (
                            <option key={codeJournal.id} value={codeJournal.id}>
                              {codeJournal.libelle}
                            </option>
                          ))}
                        </select>  */}
                        <Select placeholder="Choisissez un code journal" style={{ width: 90, borderColor: 'red', height: '30px' }} onChange={choixCodeJournal} value={journalSaisie.journal}>
                          {codeJournal.map((codeJournal) => (
                            <Option key={codeJournal.id} value={codeJournal.id}>
                              {codeJournal.libelle}
                            </Option>
                          ))}
                        </Select>
                      </td>
                      <td className='py-2 px-1'><input type="number" value={journalSaisie.cheque} onChange={handleInputChange} name="cheque" id='cheque' placeholder='Chèque' className='border border-primary rounded w-24 px-1' /></td>
                      {/* <td className='py-2 px-4'><input type="number" placeholder='Compte' className='border border-primary rounded w-24 px-1' /></td> */}
                      <td className='py-2 px-1'>
                        <AutoComplete
                          style={{ width: 80, padding: 0 }}
                          options={options} // Les options affichées
                          onSearch={handleSearch} // Fonction appelée à chaque entrée utilisateur
                          onSelect={handleSelect} // Quand l'utilisateur sélectionne une option
                          placeholder="PCG">
                          <Input className='border-primary px-1 py-1 w-24' style={{ height: '27px', marginTop: '3px' }}/>
                        </AutoComplete>

                        {/* {journalSaisie.pcg_id && <p>ID du plan comptable sélectionné : {journalSaisie.pcg_id}</p>} */}
                      </td>
                      <td className='py-2 px-1'><input type="text" placeholder='Libellé' value={journalSaisie.libelle} onChange={handleInputChange} name='libelle' id='libelle' className='border border-primary rounded w-52 px-1' /></td>
                      {/* <td className='py-2 px-4'><input type="number" placeholder='Entrée' className='border border-primary rounded w-28 px-1' /></td> */}
                      <td className='py-2 px-1'>
                          <input type="number" placeholder='Entrée' className='border border-primary rounded w-24 px-1' name='entree' id='entree' value={journalSaisie.entree} onChange={handleEntreeChange} disabled={journalSaisie.sortie !== '' && journalSaisie.sortie !== 0} // Désactiver si sortie est remplie
                          />
                      </td>
                      {/* <td className='py-2 px-4'><input type="number" placeholder='Sortie' className='border border-primary rounded w-28 px-1' /></td> */}
                      <td className='py-2 px-1'>
                          <input type="number" placeholder='Sortie' className='border border-primary rounded w-24 px-1' name='sortie' id='sortie' value={journalSaisie.sortie} onChange={handleSortieChange} disabled={journalSaisie.entree !== '' && journalSaisie.entree !== 0} // Désactiver si entrée est remplie
                          />
                      </td>
                      <td className='py-2 px-1'>
                          <Select placeholder="Tiers" id='tiers' name='tiers' style={{ width: 90, borderColor: 'red', height: '30px' }} onChange={choixTiers}>
                            <Option value='Créance'>Créance</Option>
                            <Option value='Dette'>Dette</Option>
                          </Select>     
                          
                        
                      </td>
                      {/* <td className='py-2 px-4'><input type="number" placeholder='Débit' className='border border-primary rounded w-28 px-1' /></td> */}
                      <td className='py-2 px-1'>
                          <input type="number" placeholder='Débit' className='border border-primary rounded w-24 px-1' name='debit' value={journalSaisie.debit} onChange={handleDebitChange}disabled={journalSaisie.credit !== '' && journalSaisie.credit !== 0} // Désactiver si sortie est remplie
                          />
                      </td>
                      {/* <td className='py-2 px-4'><input type="number" placeholder='Crédit' className='border border-primary rounded w-28 px-1' /></td> */}
                      <td className='py-2 px-1'>
                          <input type="number" placeholder='Crédit' className='border border-primary rounded w-24 px-1' name='debit' value={journalSaisie.credit} onChange={handleCreditChange}disabled={journalSaisie.debit !== '' && journalSaisie.debit !== 0} // Désactiver si sortie est remplie
                          />
                      </td>
                      <td className='py-2 px-1 flex justify-center items-center space-x-1'>
                        <FaPlusCircle onClick={ showConfirmAjout} className='text-primary cursor-pointer hover:scale-110 transition-transform'  style={{ width: '25px', height: '25px' }}/>
                        <MdCancel onClick={supInput} className='text-red-500 cursor-pointer hover:scale-110 transition-transform'  style={{ width: '30px', height: '29px' }}/>
                      </td>
                    </tr>

                    <tr className="bg-gray-400 border-b text-white">
                      <th className="py-2 px-2 text-left whitespace-nowrap">Date</th>
                      <th className="py-2 px-1 text-left whitespace-nowrap">Référence</th>
                      <th className="py-2 px-1 text-left whitespace-nowrap">Facture</th>
                      <th className="py-2 px-1 text-left whitespace-nowrap">Journal</th>
                      <th className="py-2 px-1 text-left whitespace-nowrap">Chèque</th>
                      <th className="py-2 px-1 text-left whitespace-nowrap">Compte</th>
                      <th className="py-2 px-1 text-left whitespace-nowrap">Libellé</th>
                      <th className="py-2 px-1 text-left whitespace-nowrap">Entrée</th>
                      <th className="py-2 px-1 text-left whitespace-nowrap">Sortie</th>
                      <th className="py-2 px-1 text-left whitespace-nowrap">Tiers</th>
                      <th className="py-2 px-1 text-left whitespace-nowrap">Débit</th>
                      <th className="py-2 px-1 text-left whitespace-nowrap">Crédit</th>
                      <th className="py-2 px-4 text-left whitespace-nowrap">Actions</th>
                    </tr>
                  </thead>
                  {loading ? (
                    // Spinner de chargement avec TailwindCSS
                      <tbody>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td className='py-5'>
                              <div className="flex justify-center items-center ">
                                <div className="w-12 h-12 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
                              </div>
                          </td>

                        </tr>
                      </tbody>
                  ) : (
                    <tbody>
                      {data.length === 0 ? (
                        <tr>
                          <td colSpan="8" className="text-center py-4">
                            <div>Aucune donnée disponible</div>
                          </td>
                        </tr>
                      ) : (
                        filteredData.map((journal, index) => (
                          <tr key={journal.id} className={`border-b ${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}>
                            {modJournalId === journal.id ? (
                              <>
                                <td className="py-2 px-2 whitespace-nowrap">
                                  <input type="date" value={modJournal.date} onChange={handleInputModChange} name="date" id='date' className='border border-primary rounded w-32' />                                </td>
                                <td className="py-2 px-1 whitespace-nowrap">
                                  <input type="number" value={modJournal.ref} onChange={handleInputModChange} name='ref' id='ref'  placeholder='Référence' className='border border-primary rounded w-24 px-1' />
                                </td>
                                <td className="py-2 px-1 whitespace-nowrap">
                                  <input type="number" value={modJournal.facture} onChange={handleInputModChange} name='facture' id='facture' placeholder='Facture' className='border border-primary rounded w-24 px-1' />
                                </td>
                                <td>
                                  <Select placeholder="Choisissez un code journal" style={{ width: 100, borderColor: 'red', height: '30px' }} onChange={choixCodeJournalMod} value={modJournal.journal_id}>
                                    {codeJournal.map((codeJournal) => (
                                      <Option key={codeJournal.id} value={codeJournal.id}>
                                        {codeJournal.libelle}
                                      </Option>
                                    ))}
                                  </Select>
                                  {/* {modJournal.journal_id && <p>ID du plan comptable sélectionné : {modJournal.journal_id}</p>} */}
                                </td>
                                <td className='py-2 px-1'><input type="number" value={modJournal.cheque} onChange={handleInputModChange} name="cheque" id='cheque' placeholder='Chèque' className='border border-primary rounded w-24 px-1' /></td>
                                <td className='py-2 px-1'>
                                    <AutoComplete
                                      style={{ width: 120, padding: 0 }}
                                      options={options} // Les options affichées
                                      onSearch={handleSearch} // Fonction appelée à chaque entrée utilisateur
                                      onSelect={handleSelectMod} // Quand l'utilisateur sélectionne une option
                                      defaultValue={modJournal.pcg.numCompte}
                                      placeholder="Numéro de compte">
                                      <Input className='border-primary px-1 py-1 w-24' value={modJournal.pcg_id} style={{ height: '27px', marginTop: '3px' }}/>
                                    </AutoComplete>

                                    {/* {modJournal.pcg_id && <p>ID du plan comptable sélectionné : {modJournal.pcg_id}</p>} */}
                                  </td>
                                  <td className='py-2 px-1'><input type="text" placeholder='Libellé' value={modJournal.libelle} onChange={handleInputModChange} name='libelle' id='libelle' className='border border-primary rounded w-44 px-1' /></td>
                                  <td className='py-2 px-1'>
                                      <input type="number" placeholder='Entrée' className='border border-primary rounded w-28 px-1' name='entree' id='entree' value={modJournal.entree} onChange={handleEntreeChangeMod} disabled={modJournal.sortie !== '' && modJournal.sortie !== 0} // Désactiver si sortie est remplie
                                      />
                                  </td>
                                  {/* <td className='py-2 px-4'><input type="number" placeholder='Sortie' className='border border-primary rounded w-28 px-1' /></td> */}
                                  <td className='py-2 px-1'>
                                      <input type="number" placeholder='Sortie' className='border border-primary rounded w-28 px-1' name='sortie' id='sortie' value={modJournal.sortie} onChange={handleSortieChangeMod} disabled={modJournal.entree !== '' && modJournal.entree !== 0} // Désactiver si entrée est remplie
                                      />
                                  </td>
                                  <td className='py-2 px-1'>
                                      <Select placeholder="Tiers" defaultValue={modJournal.tiers} style={{ width: 100, borderColor: 'red', height: '30px' }} onChange={choixTiersMod} value={modJournal.tiers}>
                                          <Option value='Créance'>Créance</Option>
                                          <Option value='Dette'>Dette</Option>
                                      </Select>
                                  </td>
                                  <td className='py-2 px-1'>
                                      <input type="number" placeholder='Débit' className='border border-primary rounded w-28 px-1' name='debit' value={modJournal.debit} onChange={handleDebitChangeMod}disabled={modJournal.credit !== '' && modJournal.credit !== 0} // Désactiver si sortie est remplie
                                      />
                                  </td>
                                  {/* <td className='py-2 px-4'><input type="number" placeholder='Crédit' className='border border-primary rounded w-28 px-1' /></td> */}
                                  <td className='py-2 px-1'>
                                      <input type="number" placeholder='Crédit' className='border border-primary rounded w-28 px-1' name='debit' value={modJournal.credit} onChange={handleCreditChangeMod}disabled={modJournal.debit !== '' && modJournal.debit !== 0} // Désactiver si sortie est remplie
                                      />
                                  </td>
                                {/* Ajoutez le reste des colonnes à éditer */}
                                <td className="py-2 px-4">
                                  <div className='flex space-x-3'>
                                    <button className='btn btn-sm border-2 border-primary bg-white text-black hover:bg-primary hover:text-white px-2 rounded-lg' onClick={showConfirmMod}>Enregistrer</button>
                                    <button className='btn btn-sm border-2 border-red-500 bg-white text-black hover:bg-red-500 hover:text-white px-2 rounded-lg' onClick={handleCancelEdit}>Annuler</button>
                                  </div>
                                </td>
                              </>
                            ) : (
                            <>
                              <td className="py-2 px-2 whitespace-nowrap">{formatDateToDisplay(journal.date)}</td>
                              <td className="py-2 px-1 whitespace-nowrap">{journal.ref === 'null' ? '' : journal.ref}</td>
                              <td className="py-2 px-1 whitespace-nowrap">{journal.facture === 'null' ? '' : journal.facture}</td>
                              <td className="py-2 px-1 whitespace-nowrap">{journal.journal.abreviation}</td>
                              <td className="py-2 px-1 whitespace-nowrap">{journal.cheque === 'null' ? '' : journal.cheque }</td>
                              <td className="py-2 px-1 whitespace-nowrap">{journal.pcg.numCompte}</td>
                              <td className="py-2 px-1 whitespace-nowrap">{journal.libelle}</td>
                              <td className="py-2 px-1 whitespace-nowrap">{journal.entree.toLocaleString('fr-FR')}</td>
                              <td className="py-2 px-1 whitespace-nowrap">{journal.sortie.toLocaleString('fr-FR')}</td>
                              <td className="py-2 px-4 whitespace-nowrap">{journal.tiers === 'null' ? '' : journal.tiers  }</td>
                              <td className="py-2 px-1 whitespace-nowrap">{journal.debit.toLocaleString('fr-FR')}</td>
                              <td className="py-2 px-1 whitespace-nowrap">{journal.credit.toLocaleString('fr-FR')}</td>
                              <td className="py-2 px-4 space-x-3">
                                {/* <EditOutlined onClick={() => showConfirmMod(journal)} style={{ fontSize: '20px', color: '#1890ff' }} className="cursor-pointer" /> */}
                                <EditOutlined onClick={() => handleEdit(journal)} style={{ fontSize: '20px', color: '#1890ff' }} className="cursor-pointer hover:scale-110 transition-transform" />
                                <DeleteOutlined onClick={() => showConfirmDelete(journal)} style={{ fontSize: '20px', color: 'red' }} className="cursor-pointer hover:scale-110 transition-transform" />
                              </td>
                            </>
                            )}
                          </tr>
                        ))
                      )}
                    </tbody>

                  )}
                </table>
              </div>
          </div>

          {/* Pagination */}
          <div className='mt-5 flex items-center space-x-2'>
              {/* Bouton Précédent */}
              {currentPage > 1 && (
                <button
                  className='bg-green-700 hover:bg-green-800 px-3 py-1 rounded-lg text-white'
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <LeftOutlined />
                </button>
              )}

              {/* Numéros de page */}
              {Array.from({ length: lastPage }, (_, i) => i + 1)
                .filter((page) => {
                  // Affiche toujours les pages 1 et lastPage
                  if (page === 1 || page === lastPage) return true;
                  // Affiche les pages dans une fourchette autour de la page actuelle
                  return page >= currentPage - 2 && page <= currentPage + 2;
                })
                .map((page, index, pages) => (
                  <>
                    {/* Affiche des points de suspension si l'écart entre les pages est trop grand */}
                    {index > 0 && page > pages[index - 1] + 1 && <span key={`dots-${page}`} className="px-1">...</span>}
                    
                    <button
                      key={page}
                      className={`px-3 py-1 rounded-lg ${
                        page === currentPage ? 'bg-blue-700 text-white' : 'bg-gray-200 text-black hover:bg-gray-300'
                      }`}
                      onClick={() => handlePageChange(page)}
                    >
                      {page}
                    </button>
                  </>
                ))
              }

              {/* Bouton Next */}
              {currentPage < lastPage && (
                <button
                  className='bg-green-700 hover:bg-green-800 px-3 py-1 rounded-lg text-white'
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <RightOutlined />
                </button>
              )}
            </div>


        </div>
      </div>
      
    </div>
  )
}

export default JournalSaisie

