import React, { useEffect, useState } from 'react';
import photo from '../../Assets/photoUser.jpg';
import { DownOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { Dropdown, Space, Spin, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { api } from '../../api';

const Header = ({ titre }) => {
  const navigate = useNavigate();

  const token = sessionStorage.getItem('token');
  const nomUser = sessionStorage.getItem('nomUser');
  const prenomUser = sessionStorage.getItem('prenomUser');
  const photo = sessionStorage.getItem('photoUser');

  const [isLoading, setIsLoading] = useState(false); // État pour gérer le chargement

  const logout = async () => {
    setIsLoading(true); // Début du chargement
    try {
      const response = await axios.post(
        api + '/api/deconnexion',
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);

      sessionStorage.removeItem('token');
      sessionStorage.removeItem('user_id');
      sessionStorage.removeItem('admin_id');
      navigate('/connexion');
    } catch (error) {
      console.error('Erreur lors de la déconnexion:', error);
    } finally {
      setIsLoading(false); // Fin du chargement
    }
  };

  const showConfirmLogout = () => {
    Modal.confirm({
      title: 'Voulez-vous vraiment vous déconnecter ?',
      icon: <ExclamationCircleFilled />,
      okText: isLoading ? <Spin size="small" /> : 'Oui, se déconnecter', // Spinner pendant le chargement
      cancelText: 'Non, annuler',
      okButtonProps: {
        style: { backgroundColor: 'red', borderColor: 'red', color: 'white' },
        disabled: isLoading, // Désactiver le bouton pendant le chargement
      },
      onOk: async () => {
        await logout(); // Attendre la fin de la déconnexion
        Modal.destroyAll(); // Fermer le modal après la déconnexion
      },
      onCancel() {
        // console.log('Déconnexion annulée');
      },
    });
  };

  const items = [
    {
      label: (
        <p onClick={() => navigate('/admin/profil')} className="f font-semibold hover:text-primary">
          <UserOutlined className="pr-2" />
          Mon profil
        </p>
      ),
      key: '0',
    },
    {
      label: (
        <p className="f font-semibold text-red-600" onClick={showConfirmLogout}>
          <LogoutOutlined className="pr-2" />
          Se déconnecter
        </p>
      ),
      key: '1',
    },
  ];

  return (
    <div className="ml-8 mr-7 py-2 px-4 bg-blue-200 shadow-md rounded-lg mt-1 flex justify-between items-center space-x-4">
      <div className="font-bold text-xl text-gray-800">{titre}</div>
      <div className="flex items-center space-x-4">
        <div>
          <img
            src={`${api}/storage/${photo}`}
            alt="User"
            className="w-10 h-10 object-cover rounded-full shadow-sm"
          />
        </div>
        <div className="text-center font-medium text-gray-700">
          <Dropdown
            menu={{
              items,
            }}
            trigger={['click']}
          >
            <a
              onClick={(e) => e.preventDefault()}
              className="text-gray-800 font-medium flex items-center space-x-2"
            >
              <Space>
                <span className="cursor-pointer text-base hover:text-blue-500">
                  {nomUser} {prenomUser} <DownOutlined className="w-3 h-3" />
                </span>
              </Space>
            </a>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default Header;
