import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js';
import { api } from '../../../api';
import axios from 'axios';

// Enregistrement des éléments nécessaires dans ChartJS
ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend);

const LineChart = ({annee}) => {
  const user_id = sessionStorage.getItem('user_id');
  const [loading, setLoading] = useState(true);

  const [CAMois1, setCAMois1] = useState(0); const [depenseMois1, setDepenseMois1] = useState(0); const [resultatMois1, setResultatMois1] = useState(0); 
  const [CAMois2, setCAMois2] = useState(0); const [depenseMois2, setDepenseMois2] = useState(0); const [resultatMois2, setResultatMois2] = useState(0); 
  const [CAMois3, setCAMois3] = useState(0); const [depenseMois3, setDepenseMois3] = useState(0); const [resultatMois3, setResultatMois3] = useState(0); 
  const [CAMois4, setCAMois4] = useState(0); const [depenseMois4, setDepenseMois4] = useState(0); const [resultatMois4, setResultatMois4] = useState(0); 
  const [CAMois5, setCAMois5] = useState(0); const [depenseMois5, setDepenseMois5] = useState(0); const [resultatMois5, setResultatMois5] = useState(0); 
  const [CAMois6, setCAMois6] = useState(0); const [depenseMois6, setDepenseMois6] = useState(0); const [resultatMois6, setResultatMois6] = useState(0); 
  const [CAMois7, setCAMois7] = useState(0); const [depenseMois7, setDepenseMois7] = useState(0); const [resultatMois7, setResultatMois7] = useState(0); 
  const [CAMois8, setCAMois8] = useState(0); const [depenseMois8, setDepenseMois8] = useState(0); const [resultatMois8, setResultatMois8] = useState(0); 
  const [CAMois9, setCAMois9] = useState(0); const [depenseMois9, setDepenseMois9] = useState(0); const [resultatMois9, setResultatMois9] = useState(0); 
  const [CAMois10, setCAMois10] = useState(0); const [depenseMois10, setDepenseMois10] = useState(0); const [resultatMois10, setResultatMois10] = useState(0); 
  const [CAMois11, setCAMois11] = useState(0); const [depenseMois11, setDepenseMois11] = useState(0); const [resultatMois11, setResultatMois11] = useState(0); 
  const [CAMois12, setCAMois12] = useState(0); const [depenseMois12, setDepenseMois12] = useState(0); const [resultatMois12, setResultatMois12] = useState(0); 


  // Récuperer les données dans le barChart
  const getDonneeLineChart = async () => {
    try {

        const response = await axios.get(api + `/api/getBilanAnnuel/${annee}/${user_id}`);
      
        const CAMois1Value = parseFloat(response.data.CAMois1); const depenseMois1Value = parseFloat(response.data.depenseMois1); const resultativeMois1Value = parseFloat(response.data.resultatMois1);
        const CAMois2Value = parseFloat(response.data.CAMois2); const depenseMois2Value = parseFloat(response.data.depenseMois2); const resultativeMois2Value = parseFloat(response.data.resultatMois2);
        const CAMois3Value = parseFloat(response.data.CAMois3); const depenseMois3Value = parseFloat(response.data.depenseMois3); const resultativeMois3Value = parseFloat(response.data.resultatMois3);
        const CAMois4Value = parseFloat(response.data.CAMois4); const depenseMois4Value = parseFloat(response.data.depenseMois4); const resultativeMois4Value = parseFloat(response.data.resultatMois4);
        const CAMois5Value = parseFloat(response.data.CAMois5); const depenseMois5Value = parseFloat(response.data.depenseMois5); const resultativeMois5Value = parseFloat(response.data.resultatMois5);
        const CAMois6Value = parseFloat(response.data.CAMois6); const depenseMois6Value = parseFloat(response.data.depenseMois6); const resultativeMois6Value = parseFloat(response.data.resultatMois6);
        const CAMois7Value = parseFloat(response.data.CAMois7); const depenseMois7Value = parseFloat(response.data.depenseMois7); const resultativeMois7Value = parseFloat(response.data.resultatMois7);
        const CAMois8Value = parseFloat(response.data.CAMois8); const depenseMois8Value = parseFloat(response.data.depenseMois8); const resultativeMois8Value = parseFloat(response.data.resultatMois8);
        const CAMois9Value = parseFloat(response.data.CAMois9); const depenseMois9Value = parseFloat(response.data.depenseMois9); const resultativeMois9Value = parseFloat(response.data.resultatMois9);
        const CAMois10Value = parseFloat(response.data.CAMois10); const depenseMois10Value = parseFloat(response.data.depenseMois10); const resultativeMois10Value = parseFloat(response.data.resultatMois10);
        const CAMois11Value = parseFloat(response.data.CAMois11); const depenseMois11Value = parseFloat(response.data.depenseMois11); const resultativeMois11Value = parseFloat(response.data.resultatMois11);
        const CAMois12Value = parseFloat(response.data.CAMois12); const depenseMois12Value = parseFloat(response.data.depenseMois12); const resultativeMois12Value = parseFloat(response.data.resultatMois12);


        setCAMois1(CAMois1Value); setDepenseMois1(depenseMois1Value); setResultatMois1(resultativeMois1Value);
        setCAMois2(CAMois2Value); setDepenseMois2(depenseMois2Value); setResultatMois2(resultativeMois2Value);
        setCAMois3(CAMois3Value); setDepenseMois3(depenseMois3Value); setResultatMois3(resultativeMois3Value);
        setCAMois4(CAMois4Value); setDepenseMois4(depenseMois4Value); setResultatMois4(resultativeMois4Value);
        setCAMois5(CAMois5Value); setDepenseMois5(depenseMois5Value); setResultatMois5(resultativeMois5Value);
        setCAMois6(CAMois6Value); setDepenseMois6(depenseMois6Value); setResultatMois6(resultativeMois6Value);
        setCAMois7(CAMois7Value); setDepenseMois7(depenseMois7Value); setResultatMois7(resultativeMois7Value);
        setCAMois8(CAMois8Value); setDepenseMois8(depenseMois8Value); setResultatMois8(resultativeMois8Value);
        setCAMois9(CAMois9Value); setDepenseMois9(depenseMois9Value); setResultatMois9(resultativeMois9Value);
        setCAMois10(CAMois10Value); setDepenseMois10(depenseMois10Value); setResultatMois10(resultativeMois10Value);
        setCAMois11(CAMois11Value); setDepenseMois11(depenseMois11Value); setResultatMois11(resultativeMois11Value);
        setCAMois12(CAMois12Value); setDepenseMois12(depenseMois12Value); setResultatMois12(resultativeMois12Value);


        // setDataExist(response.data.existe)
        setLoading(false)
        console.log(response.data);

    } catch (error) {
      console.error('Error fetching entity:', error);
    }
  };

  useEffect(() => {
    getDonneeLineChart();
  }, []);
  // Données du graphique
  const data = {
    labels: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'], // Labels pour l'axe des X
    datasets: [
      {
        label: 'CA', // Légende pour le premier dataset
        data: [CAMois1, CAMois2, CAMois3, CAMois4, CAMois5, CAMois6, CAMois7, CAMois8 ,CAMois9, CAMois10, CAMois11, CAMois12], // Données pour le premier dataset
        backgroundColor: 'rgba(77, 219, 255, 0.5)', // Couleur de fond (transparent pour les lignes)
        borderColor: 'rgba(77, 219, 255, 1)', // Couleur de la ligne
        borderWidth: 2, // Épaisseur de la ligne
        fill: false, // Ne pas remplir l'aire sous la courbe
      },
      {
        label: 'Dépenses', // Légende pour le premier dataset
        data: [depenseMois1, depenseMois2, depenseMois3, depenseMois4, depenseMois5, depenseMois6, depenseMois7, depenseMois8, depenseMois9, depenseMois10, depenseMois11, depenseMois12 ], // Données pour le premier dataset
        backgroundColor: 'rgba(255, 140, 26, 0.5)', // Couleur de fond (transparent pour les lignes)
        borderColor: 'rgba(255, 140, 26, 1)', // Couleur de la ligne
        borderWidth: 2, // Épaisseur de la ligne
        fill: false, // Ne pas remplir l'aire sous la courbe
      },
      {
        label: 'Résultat', // Légende pour le deuxième dataset
        data: [resultatMois1, resultatMois2, resultatMois3, resultatMois4, resultatMois5, resultatMois6, resultatMois7, resultatMois8, resultatMois9, resultatMois10, resultatMois11, resultatMois12], // Données pour le deuxième dataset
        backgroundColor: 'rgb(204, 204, 204, 0.5)',
        borderColor: 'rgb(204, 204, 204, 1)',
        borderWidth: 2,
        fill: false,
      },
    ],
  };

  // Options de configuration du graphique
  const options = {
    responsive: true,
    plugins: {
      legend: { display: true }, // Afficher les légendes
      tooltip: { mode: 'index' }, // Afficher les tooltips pour les points
    },
    scales: {
      y: {
        beginAtZero: true, // L'axe Y commence à 0
        title: {
          display: false,
          text: 'Valeur', // Titre pour l'axe Y
        },
      },
      x: {
        title: {
          display: false,
          text: 'Mois', // Titre pour l'axe X
        },
      },
    },
  };

  return loading ? (

        <>
           <div className="flex justify-center items-center mt-28">
            <div className="w-12 h-12 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
          </div>
        </>

  ) : (
    <div className="w-full "> {/* Fixer la hauteur du conteneur */}
    <Line data={data} options={options}  height={90}/>
  </div>
  );
};

export default LineChart;
