export const fadeIn = (direction, delay) => {
  return {
    hidden: {
      y: direction === 'up' ? 40 : direction === 'down' ? -40 : 0,
      opacity: 0,
      x: direction === 'left' ? 40 : direction === 'right' ? -40 : 0,
    },
    show: {
      y: 0,
      opacity: 1,
      x: 0,
      transition: {
        delay: delay,
        duration: 0.5,
        type: 'tween',
        ease: [0.25, 0.25, 0.25, 0.75],
      },
    }
  }
}