import React from 'react'
import {Menu} from 'antd'
import {HomeOutlined, FormOutlined, FileTextOutlined, CalendarOutlined} from '@ant-design/icons'
import {UsergroupDeleteOutlined, EditOutlined, BellOutlined, SettingOutlined, FileAddOutlined} from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

const MenuList = () => {

  const getCurrentMonth = () => {
    const month = new Date().getMonth() + 1;
    return month;
  }

  const getCurrentYear = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear()
    return year;
  }

  const navigate = useNavigate()

  const toAnneeFiscale = () => {
    navigate('/admin/anneeFiscale')
  }

  const toDashboard = () => {
    navigate('/admin/')
  }

  const toCodeJournale = () => {
    navigate('/admin/codeJournale')
  }

  const toPlanComptable = () => {
    navigate('/admin/planComptable')
  }

  const toUtilisateur = () => {
    navigate('/admin/utilisateur')
  }

  const toProfil = () => {
    navigate('/admin/profil')
  }

  const toEntite = () => {
    navigate('/admin/entite')
  }

  const toAboActuel = () => {
    navigate('/admin/actuel')
  }

  const toDemande = () => {
    navigate('/admin/demande')
  }

  const toHistorique = () => {
    navigate('/admin/historique')
  }

  const toJournalSaisie = () => {
    navigate('/admin/journalSaisie/' + getCurrentMonth() + '/' + getCurrentYear())
  }

  return (
    <Menu theme='light' className='pl-2 mt-3 h-[88vh] flex flex-col gap-[5px]' mode='inline'>
      <Menu.Item onClick={toDashboard} style={{ color: '#949494' }}  className='s font-semibold' key="Tableau-bord" icon={<HomeOutlined style={{ color: '#0071BD' }}/>}>
        Tableau de bord
      </Menu.Item>

      <Menu.Item onClick={toCodeJournale} style={{ color: '#949494' }}  className='s font-semibold' key="Journale-saisie" icon={<FormOutlined style={{ color: '#0071BD' }}/>}>
        Code journal
      </Menu.Item>

      <Menu.Item onClick={toPlanComptable} style={{ color: '#949494' }}  className='s font-semibold' key="Plan-comptable" icon={<FileTextOutlined style={{ color: '#0071BD' }}/>}>
        Plan comptable
      </Menu.Item>

      <Menu.Item onClick={toAnneeFiscale} style={{ color: '#949494' }}  className='s font-semibold' key="Code-journal" icon={<CalendarOutlined style={{ color: '#0071BD' }}/>}>
         Année fiscale
      </Menu.Item>

      <Menu.Item onClick={toJournalSaisie} style={{ color: '#949494' }}  className='s font-semibold' key="Annee-fiscale" icon={<FileAddOutlined style={{ color: '#0071BD' }}/>}>
         Journal de saisie
      </Menu.Item>

      <Menu.SubMenu style={{ color: '#949494' }}  className='s font-semibold' key="Abonnement" icon={<BellOutlined style={{ color: '#0071BD' }}/>} title={<span style={{ color: '#949494' }}>Abonnement</span>}>
         <Menu.Item onClick={toAboActuel} key="Actuelle" className=''>Actuel</Menu.Item>
         <Menu.Item onClick={toDemande} key="Demande">Demande</Menu.Item>
         <Menu.Item onClick={toHistorique} key="Historique">Historique</Menu.Item>
      </Menu.SubMenu>

      <Menu.Item onClick={toUtilisateur} style={{ color: '#949494' }}  className='s font-semibold' key="Utilisateur" icon={<UsergroupDeleteOutlined style={{ color: '#0071BD' }}/>}>
         Utilisateur
      </Menu.Item>

      
      {/* <Menu.SubMenu key="SubAbonnement" icon={<BarsOutlined />} title="Abonnements">

      </Menu.SubMenu> */}


      <Menu.SubMenu style={{ color: '#949494' }}  className='s font-semibold' key="Parametre" icon={<SettingOutlined style={{ color: '#0071BD' }}/>} title={<span style={{ color: '#949494' }}>Paramètre</span>}>
         <Menu.Item onClick={toEntite} key="Entité" className=''>Entité</Menu.Item>
         <Menu.Item onClick={toProfil} key="Profil">Profil</Menu.Item>
      </Menu.SubMenu>
    </Menu>
  )
}

export default MenuList