import React, { useState } from 'react';
import img1 from '../../Assets/imgInscription.jpg'
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Button, message, Space } from 'antd';
import { api } from '../../api';

const Inscription = () => {

  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Inscription réussie !',
    });
  };

  const infoIncomplete = () => {
    messageApi.open({
      type: 'info',
      content: 'Tous les champs sont obligatoires !',
    });
  };

  const {email} = useParams()
  const navigate = useNavigate()
  const [emailUser, setEmailUser] = useState(email)
  const [confPassword, setConfPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false);

  const [userInfo, setUserInfo] = useState({
    photo: '',
    nom: '',
    prenom: '',
    contact: '',
    adresse: '',
    password: '',
    email: email,
  })

  const [selectedImage, setSelectedImage] = useState('');

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file); // Créer une URL pour l'image
      setSelectedImage(imageUrl); // Mettre à jour l'image sélectionnée
      // console.log(file)
      // setPhoto(file)
      setUserInfo({
        ...userInfo,
        photo: file,
      })
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({
      ...userInfo,
      [name]: value,
    });
  
}

  // Fonction pour s'inscrire
  const inscription = () => {
    setLoading(true);

    if (userInfo.nom === '' || userInfo.prenom === '' || userInfo.adresse === '' || userInfo.contact || userInfo.password === '' || confPassword === '')
    {
      // alert('Tous les champs sont obligatoires')
      infoIncomplete()
      setLoading(false);
    }
    // Vérifier si le mot de passe et la confirmation correspondent
    if (userInfo.password !== confPassword) {
      setErrorMessage('Veuillez confirmer correctement votre mot de passe !');
      setConfPassword('')
      setLoading(false)
      return;
    }


    const formData = new FormData();
    formData.append('nom', userInfo.nom);
    formData.append('prenom', userInfo.prenom);
    formData.append('contact', userInfo.contact);
    formData.append('adresse', userInfo.adresse);
    formData.append('password', userInfo.password);
    formData.append('email', userInfo.email);
    formData.append('role', 'admin');
    formData.append('etat', 1);

    if (userInfo.photo) {
      formData.append('photo', userInfo.photo);
    }
    
    try {
      axios.post(api + '/api/inscription', formData)
          .then(res => {
              // Gérer la réponse en cas de succès
              console.log('Inscription réussie:', res.data);
              success()
              setLoading(false);
              
              setTimeout(() => {
                navigate('/bienvenue');
              }, 4000); // 4000 ms = 4 secondes
          })
          .catch(err => {
              // Gérer les erreurs de la requête
              if (err.response) {
                  // Le serveur a répondu avec un code de statut différent de 2xx
                  console.error('Erreur réponse serveur:', err.response.data);
              } else if (err.request) {
                  // La requête a été faite, mais aucune réponse reçue
                  console.error('Aucune réponse reçue:', err.request);
              } else {
                  // Autre erreur lors de la configuration de la requête
                  console.error('Erreur lors de la requête:', err.message);
              }
          });
    } catch (error) {
        // Gérer les erreurs inattendues dans le bloc try
        console.error('Erreur inattendue:', error);
    }
  
     // Pour afficher le contenu de formData
    //  formData.forEach((value, key) => {
    //   console.log(`${key}: ${value}`);
    // });

    // console.log(userInfo)
  };

  return (
    <div className="flex flex-col md:flex-row min-h-screen">

      {contextHolder}
      
      {/* Image à gauche */}
      <div className="w-1/2 flex md:p-0 max-sm:hidden">
        <img src={img1} alt="Placeholder" className="w-full object-cover h-auto" />
      </div>

      {/* Formulaire à droite */}
      <div className="w-full md:w-1/2 md:p-8 max-sm:mt-10 max-sm:px-5 mx-auto flex flex-col items-center justify-center h-screen">
        <h2 className="text-2xl font-bold mb-1 text-center md:text-left">Etape 2</h2>
        <h2 className="text-2xl font-bold mb-3 text-center md:text-left">Création du compte</h2>

        
          <form className="w-full lg:px-20">

            <div className="flex flex-col items-center justify-center">
                  {/* Champ de type file caché */}
                  <input
                    id="fileInput"
                    type="file"
                    accept="image/*"
                    className="hidden" 
                    onChange={handleFileChange} 
                  />

                  {/* Label circulaire pour déclencher l'upload */}
                  <label
                    htmlFor="fileInput"
                    className="cursor-pointer flex items-center justify-center w-20 h-20 bg-gray-200 rounded-full overflow-hidden border border-dashed border-gray-400 hover:bg-gray-300"
                  >
                    {selectedImage ? (
                      <img
                        src={selectedImage}
                        alt="Selected"
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <span className="text-gray-500 text-sm text-center">Sélectionner une image</span>
                    )}
                  </label>
            </div>

            <div className="mb-2">
              <label className="block mb-2 font-semibold">Email</ label>
              <input type="email" value={emailUser} className="w-full border rounded-lg border-gray-300 px-2 py-1" placeholder="" />
            </div>
            <div className="mb-2">
              <label className="block mb-1 font-semibold">Nom</label>
              <input type="text" value={userInfo.nom} name='nom' onChange={handleInputChange} required className="appearance-none w-full border rounded-lg border-gray-300 px-2 py-1 focus:outline-none focus:ring-primary focus:border-primary" placeholder="Entrez votre nom" />
            </div>
            <div className="mb-2">
              <label className="block mb-1 font-semibold">Prénom(s)</label>
              <input type="text" value={userInfo.prenom} name='prenom' onChange={handleInputChange} className="w-full border rounded-lg border-gray-300 px-2 py-1 focus:ring-primary focus:outline-none focus:border-primary" placeholder="Entrez votre prénom" />
            </div>
            <div className="mb-2">
              <label className="block mb-1 font-semibold">Contact</label>
              <input type="text" value={userInfo.contact} name='contact' onChange={handleInputChange} required className="w-full border rounded-lg border-gray-300 px-2 py-1 focus:outline-none focus:ring-primary focus:border-primary" placeholder="Entrez votre contact" />
            </div>
            <div className="mb-2">
              <label className="block mb-1 font-semibold">Adresse</label>
              <input type="text" value={userInfo.adresse} name='adresse' onChange={handleInputChange} required className="w-full border rounded-lg border-gray-300 px-2 py-1 focus:outline-none focus:ring-primary focus:border-primary" placeholder="Entrez votre adresse" />
            </div>
            {errorMessage && (
              <div className="text-red-500 font-semibold">
                {errorMessage}
              </div>
            )}
            <div className='mb-2 grid grid-cols-1 lg:grid-cols-2 gap-x-8'>
              <div>
                <label htmlFor="password" className="block mb-1 font-semibold">Mot de passe</label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  // autoComplete="current-password"
                  required
                  value={userInfo.password} onChange={handleInputChange}
                  className="appearance-none relative block w-full px-2 py-1 border border-gray-300 rounded-lg focus:outline-none focus:ring-primary focus:border-primary focus:z-10 max-sm:sm:text-sm"
                  placeholder="**********"
                />
              </div>

              <div>
                <label htmlFor="password" className="block mb-1 font-semibold">Confirmation du mot de passe</label>
                <input
                  id="confPassword"
                  name="confPassword"
                  type="password"
                  // autoComplete="current-password"
                  required
                  value={confPassword}
                  onChange={(e) => setConfPassword(e.target.value)}
                  className="appearance-none relative block w-full px-2 py-1 border border-gray-300 rounded-lg focus:outline-none focus:ring-primary focus:border-primary focus:z-10 max-sm:sm:text-sm"
                  placeholder="**********"
                />
              </div>
            </div>
            {/* <div className='mb-2'>
              <label htmlFor="password" className="block mb-1 font-semibold">Confirmation du mot de passe</label>
              <input
                id="confPassword"
                name="confPassword"
                type="confPassword"
                // autoComplete="current-password"
                required
                // value={password}
                // onChange={(e) => setPassword(e.target.value)}
                className="appearance-none relative block w-full px-2 py-1 border border-gray-300 rounded-lg focus:outline-none focus:ring-primary focus:border-primary focus:z-10 max-sm:sm:text-sm"
                placeholder="Confirmer votre mot de passe"
              />
            </div> */}

            {
              loading ? (
                  <>
                      <button
                        type="button"
                        className="bg-primary text-white px-4 py-2 font-semibold rounded w-full mt-4"
                      >
                        <div className='justify-center items-center flex'>
                            <div className="w-6 h-6 justify-center items-center border-4 border-white border-dashed rounded-full animate-spin"></div>
                        </div>
                      </button>
                  </>
              ) : (
                <>
                  <button
                    type="button"
                    onClick={inscription}
                    className="bg-primary text-white px-4 py-2 font-semibold rounded w-full mt-4"
                  >
                      S'inscrire
                  </button>
                </>
              )
            }

            
            {/* <Space>
              <Button onClick={success}>Success</Button>
            </Space> */}
          </form>
        </div>
      


    </div>
  );
};

export default Inscription;
