import React, { useState } from 'react'
import Logo from '../../Assets/Logo.png';
import { LeftCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { api } from '../../api';
import axios from 'axios';

const VerificationEmail = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const navigate = useNavigate();

  const revenir = () => {
    navigate('/Connexion');
  };

  const verification = async () => {
    setLoading(true);
    setError('');
    setSuccess('')

    try {

      if (email == '') {
        setError('Veuillez saisir votre adresse e-mail.')
      }

      else {
        const response = await axios.post(api + '/api/verificationEmail', {
          email
        });

        const exist = response.data.exist;
        if (exist) {
          // alert('Un utilisateur avec cet email existe déjà.')
          const response = await axios.post(api + '/api/envoyerLienForgotPassword', {
            email
          });

          setSuccess('Le lien a été envoyé à votre adresse email.');
          setLoading(false)
          setTimeout(() => {
            navigate('/');
          }, 5000); // Rediriger après 3 secondes

        }
        else {
          alert('Aucun utilisateur trouvé avec cet email.')
        }
      }

    } catch (err) {
      setError('Aucun utilisateur trouvé avec cet email.')
      console.log(err)
      
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-3">
        <div>
          <span onClick={revenir}>
            <LeftCircleOutlined style={{ fontSize: '25px', color: '#0071BD' }} className="cursor-pointer" />
          </span>
        </div>
        <div className="flex items-center justify-center">
          <img src={Logo} className="w-48 md:w-56" alt="Logo" />
        </div>
        <div>
          <h2 className="mt-3 text-center text-3xl font-extrabold text-gray-900">Mot de passe oublié</h2>
        </div>
        {error && (
          <div className="text-red-600 text-center font-semibold">
            {error}
          </div>
        )}
        {success && <p className="text-green-500 mb-4">{success}</p>}
        <div>
          <p className='text-justify'>
            Nous vous envoyons à votre adresse e-mail un lien de confirmation. Pour changer votre mot de passe, veuillez cliquer sur ce lien pour continuer les étapes.
          </p>
        </div>
        <form className="mt-10 space-y-2" onSubmit={(e) => e.preventDefault()}>
          <div className="rounded-md shadow-sm space-y-3">
            <div className="space-y-2">
              <label htmlFor="email" className="font-semibold">Adresse e-mail</label>
              <input
                id="email"
                name="email"
                type="email"
                // autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Votre e-mail"
              />
            </div>
            {/* <div className="space-y-2">
              <label htmlFor="password" className="font-semibold">Mot de passe</label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="********
                "
              />
            </div> */}
          </div>
          
          <div className=''>
            <button
              type="button"
              onClick={verification}
              disabled={loading}
              className={`group mt-4 relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              {loading ? 'En cours...' : 'Envoyer'}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default VerificationEmail