import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import axios from 'axios';
import { api } from '../../../api';
import { Empty } from 'antd';

// Enregistrement des éléments nécessaires dans ChartJS
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = () => {
  

  // Données du graphique
  const data = {
    labels: ['Octobre', 'Novembre', 'Décembre'],
    datasets: [
      {
        label: ['Total'],
        data: [1,2,3],
        
        backgroundColor: [
          'rgb(77, 219, 255, 1)',
          'rgb(255, 140, 26,1)',
          'rgba(75, 192, 192, 1)'
        ],
        borderColor: [
          'rgb(77, 219, 255, 1)',
          'rgb(255, 140, 26,1)',
          'rgba(75, 192, 192, 1)'
        ],
        borderWidth: 1,
      },
    ],
  };

    // <div className="w-full h-96"> 
    //   <Bar data={data} options={options} height={300} />
    // </div>

  // Options de configuration du graphique
  const options = {
    responsive: true,
    plugins: {
      legend: { display: false }, // Désactiver l'affichage des légendes
      // legend: { position: 'top' }, // Légendes en haut du graphique
      // title: { display: true, text: 'Sales by Month' }, // Titre du graphique
    },
    scales: {
      y: { beginAtZero: true }, // L'axe Y commence à 0
    },
  };

  return (
    
    <div className="w-full h-auto p-1 mt-2 bg-white border border-gray-200 shadow-lg rounded-lg">
      
      <div className="w-full h-40 sm:h-64 md:h-80 lg:h-72 flex justify-center items-center my-12">
        <Bar data={data} options={options} height={300} />
      </div>
    </div>)

    // <div className="w-full h-96"> 
    //    <Bar data={data} options={options} height={300} />
    // </div> )
};

export default BarChart;
