import React, { useEffect, useState } from 'react'
import SideBarKaonty from '../../../Components/SideBarKaonty'
import Header from '../../../Components/Admin/Header'
import { DownOutlined,EllipsisOutlined,ExclamationCircleFilled, FilterOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import {Modal} from 'antd'
import { api } from '../../../api';
import axios from 'axios';
import { Button, message } from 'antd';
import userDefaut from '../../../Assets/user2.png'

const Utilisateur = () => {

  const navigate = useNavigate()
  const { confirm } = Modal;

  const [messageApi, contextHolder] = message.useMessage();
  const successActivation = () => {
    messageApi.open({
      type: 'success',
      content: 'Activation du compte d\'utilisateur réussie !',
    });
  };

  const successDesactivation = () => {
    messageApi.open({
      type: 'success',
      content: 'Désactivation du compte d\'utilisateur réussie !',
    });
  };

  const user_id = sessionStorage.getItem('user_id');
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true);

  // Fonction pour rediriger vers la page des détails
  const detailUtilisateur = (id) => {
    navigate(`/admin/utilisateur/${id}`);
  };

  const ajoutCompte = () => {
    navigate(`/admin/utilisateur/ajout`);
  };

  const items = (id, etat) => {
    return [
      {
        label: <p className='f font-semibold hover:text-primary' onClick={() => detailUtilisateur(id)}>Détails</p>,
        key: '0',
      },
      // Afficher "Désactiver" uniquement si l'état est "Activé"
      ...(etat === 1 ? [{
        label: <p className='f font-semibold hover:text-red-600' onClick={() => desactiverCompte(id)}>Désactiver</p>,
        key: '1',
      }] : []),
      // Afficher "Activer" uniquement si l'état est "Désactivé"
      ...(etat === 0 ? [{
        label: <p className='f font-semibold hover:text-primary' onClick={() => activerCompte(id)}>Activer</p>,
        key: '2',
      }] : []),
    ];
  };

  // Tous les utilisateurs d'un admin
  const getAllUser = async () => {
    try {
      const response = await axios.get(api + `/api/allUser/${user_id}`);
      setData(response.data);
      setLoading(false);

    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  // Activer un compte d'utilisateur
  const activerCompteUser = async (user_id) => {
    try {
      axios.post(api + `/api/activerCompteUser/${user_id}`)
          .then(res => {
              successActivation();
              // Gérer la réponse en cas de succès
              // console.log('Suppression du code journal réussie:', res.data);
              getAllUser()
              
          })
          .catch(err => {
              // Gérer les erreurs de la requête
              if (err.response) {
                  // Le serveur a répondu avec un code de statut différent de 2xx
                  console.error('Erreur réponse serveur:', err.response.data);
              } else if (err.request) {
                  // La requête a été faite, mais aucune réponse reçue
                  console.error('Aucune réponse reçue:', err.request);
              } else {
                  // Autre erreur lors de la configuration de la requête
                  console.error('Erreur lors de la requête:', err.message);
              }
          });
    } catch (error) {
        // Gérer les erreurs inattendues dans le bloc try
        console.error('Erreur inattendue:', error);
    }
  };

  // Désctiver un compte d'utilisateur
  const desactiverCompteUser = async (user_id) => {
    try {
      axios.post(api + `/api/desactiverCompteUser/${user_id}`)
          .then(res => {
              successDesactivation();
              // Gérer la réponse en cas de succès
              // console.log('Suppression du code journal réussie:', res.data);
              getAllUser()
              
          })
          .catch(err => {
              // Gérer les erreurs de la requête
              if (err.response) {
                  // Le serveur a répondu avec un code de statut différent de 2xx
                  console.error('Erreur réponse serveur:', err.response.data);
              } else if (err.request) {
                  // La requête a été faite, mais aucune réponse reçue
                  console.error('Aucune réponse reçue:', err.request);
              } else {
                  // Autre erreur lors de la configuration de la requête
                  console.error('Erreur lors de la requête:', err.message);
              }
          });
    } catch (error) {
        // Gérer les erreurs inattendues dans le bloc try
        console.error('Erreur inattendue:', error);
    }
  };

  useEffect(() => {
    getAllUser();
  }, []);

  // const data = [
  //   { id: 1, photo: 'Photo', nom: 'RANDRIA Assil', email: 'assil@gmail.com', etat: 'Activé' },
  //   { id: 2, photo: 'Photo', nom: 'RANDRIA Lucio', email: 'lucio@gmail.com', etat: 'Activé' },
  //   { id: 3, photo: 'Photo', nom: 'RASOA Joelinah', email: 'joe@gmail.com', etat: 'Désactivé' },
  //   { id: 4, photo: 'Photo', nom: 'ARIMANANA Jules', email: 'jules@gmail.com', etat: 'Activé' },
  //   { id: 5, photo: 'Photo', nom: 'ZOENIRINA Viviane', email: 'viviane@gmail.com', etat: 'Désactivé' },
  // ];

  // Modal de confirmation d'activation du compte d'un client
  const activerCompte = (id) => {
    confirm({
      title: 'Voulez-vous vraiment activer le compte de cette utilisateur ?',
      icon: <ExclamationCircleFilled />,
      content: (
        <div>
          {/* <p>Code: {journal.id}</p>
          <p>Description: {journal.abreviation}</p>
          <p>Description: {journal.libelle}</p> */}
          {/* <p>Contenu du modal</p> */}
        </div>
      ),
      okText: 'Oui, activer', // Texte personnalisé pour le bouton OK
      cancelText: 'Non, annuler',
      okButtonProps: {
        style: { backgroundColor: 'primary', borderColor: 'primary', color: 'white' }, // Bouton OK en rouge
      },
      onOk() {
        // Activation d'un compte d'utilisateur
        activerCompteUser(id)
      },
      onCancel() {
        // alert('Annulé')
      },
    });
  };

  // Modal de confirmation du desactivation du compte d'un client
  const desactiverCompte = (id) => {
    confirm({
      title: 'Voulez-vous vraiment désactiver le compte de cette utilisateur ?' ,
      icon: <ExclamationCircleFilled />,
      content: (
        <div>
          {/* <p>Code: {journal.id}</p>
          <p>Description: {journal.abreviation}</p>
          <p>Description: {journal.libelle}</p> */}
          {/* <p>Contenu du modal</p> */}
        </div>
      ),
      okText: 'Oui, désactiver', // Texte personnalisé pour le bouton OK
      cancelText: 'Non, annuler',
      okButtonProps: {
        style: { backgroundColor: 'red', borderColor: 'primary', color: 'white' }, // Bouton OK en rouge
      },
      onOk() {
        // console.log('Supprimer');
        // alert('Désactivé')
        desactiverCompteUser(id)
      },
      onCancel() {
        // alert('Annulé')
      },
    });
  };

   // État pour les filtres
  const [searchTerm, setSearchTerm] = useState('');

  // Filtrage des données en fonction du nom (en convertissant searchTerm en nombre)
  const filteredData = data.filter((utilisateur) => {
    // Si le searchTerm est un nombre et le groupement est aussi un entier
    return utilisateur.nom.toString().includes(searchTerm) || utilisateur.prenom.toString().includes(searchTerm);
  });

  return (
    <div className='bg flex flex-row' style={{ height: '100vh' }}>
      {contextHolder}
      {/* style={{ background: '#F9F9FC' }} */}
      <div>
        <SideBarKaonty />
      </div>
      <div className='bg w-full' style={{ background: '#F9F9FC' }}>
        <div>
          <Header titre={'Utilisateur'}/>
        </div>
        <div className='pl-8 pr-7 py-4'>
          <div className='flex justify-between'>
            <div className='flex space-x-5'>
                  <div>
                    <span className='font-semibold text-lg'>Filtre <span><FilterOutlined /></span> :</span>
                  </div>
                  <div>
                    {/* <input type="text" placeholder='Recherche par nom' className='border border-primary rounded-lg w-44 px-2 text-lg' /> */}
                    <input
                    type="text"
                    placeholder="Filtrer par nom ou prénom"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="py-[1px] w-52 px-1 border border-primary rounded"
                  />
                  </div>
                </div>
              <div>
                <button className='px-4 py-1 font-semibold bg-white text-primary duration-300 border-primary rounded-lg border-2 hover:text-white hover:bg-primary' onClick={ajoutCompte}>Créer un compte</button>
              </div>
          </div>

            {/* Table */}
        <div className="overflow-x-auto mt-5 rounded-xl">

            <table className="min-w-full bg-white border border-gray-200">
              <thead>
                <tr className="bg-gray-300 border-b">
                  <th className="py-2 px-4 text-left">Photo</th>
                  <th className="py-2 px-4 text-left">Nom</th>
                  <th className="py-2 px-4 text-left">Email</th>
                  <th className="py-2 px-4 text-left">Etat</th>
                  <th className="py-2 px-4 text-left flex justify-center items-center">Actions</th>
                </tr>
              </thead>
              <tbody>
              {loading ? (
              // Spinner de chargement avec TailwindCSS
                <>
                <tr>
                  <td colSpan='5'>
                    <div className="flex justify-center items-center h-32">
                      <div className="w-10 h-10 border-4 border-blue-500 border-dashed rounded-full animate-spin"></div>
                    </div>
                  </td>
                </tr>
                </>
              ) : (
                <>
                { data.length === 0 ? (
                    <tr>
                      <td colSpan="5" className="text-center py-4">
                        <div>Aucune donnée disponible</div>
                      </td>
                    </tr>
                      ) : (
                  filteredData.map((utilisateur, index) => (
                  <tr key={utilisateur.id} className={`border-b ${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}>
                    <td className="py-2 px-4">
                    <img
                      src={ utilisateur.photo ? api+'/storage/'+utilisateur.photo : userDefaut} // Afficher l'image de l'utilisateur ou une image par défaut
                      alt=""
                      className='w w-9 h-9 object-cover rounded-full cursor-pointer'
                    />
                    </td>
                    <td className="py-2 px-4">{utilisateur.nom} {utilisateur.prenom}</td>
                    <td className="py-2 px-4">{utilisateur.email}</td>
                    <td className={`py-2 px-4
                        ${utilisateur.etat === 1 ? 'text-blue-500' : ''} 
                        ${utilisateur.etat === 0 ? 'text-red-600' : ''}`}>
                      <div className={`border inline-block px-2 rounded-full ${utilisateur.etat === 1 ? 'border-primary' : ''}
                      ${utilisateur.etat === 0 ? 'border-red-600' : ''}`}>
                        {utilisateur.etat === 1 ? 'Activé' : 'Désactivé'}
                      </div>
                    </td>
                    <td className="py-2 px-4 space-x-4 flex justify-center items-center">
                        <Dropdown
                          menu={{
                            items: items(utilisateur.id, utilisateur.etat),
                          }}
                          trigger={['click']}
                        >
                          <a
                            onClick={(e) => e.preventDefault()}
                            className="text-gray-800 font-medium flex items-center space-x-2 hover:text-blue-600"
                          >
                            <Space>
                              
                              <EllipsisOutlined style={{ fontSize: '25px', color: '#0071BD' }} className='c cursor-pointer'/>
                            </Space>
                          </a>
                        </Dropdown>
                    </td>
                  </tr>
                )))}
                </>
              )}
              </tbody>
            </table>
            
          </div>

        </div>

      </div>
      
    </div>
  )
}

export default Utilisateur

