import React, { useEffect, useState } from 'react'
import SideBarKaonty from '../../../Components/SideBarKaonty'
import Header from '../../../Components/Admin/Header'
import {LeftCircleOutlined, CloseOutlined} from '@ant-design/icons'
import { useNavigate, useParams } from 'react-router-dom'
import { api } from '../../../api'
import axios from 'axios'
import ModalImage from '../../../Components/ModalImage'

const DetailDemande = () => {

  const navigate = useNavigate()
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // État pour le modal d'image

  const revenir = () => {
    navigate('/admin/demande')
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const getInfoDemande = async () => {
    try {
      const response = await axios.get(api + `/api/infoDemande/${id}`);
      setData(response.data[0]);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const [isModalVisibleImg, setIsModalVisibleImg] = useState(false);
  const [imageToShow, setImageToShow] = useState("");

  const handleImageClick = (imageUrl) => {
    setImageToShow(imageUrl);
    setIsModalVisibleImg(true);
  };

  const closeModal = () => {
    setIsModalVisibleImg(false);
    setImageToShow("");
  };

  useEffect(() => {
    getInfoDemande();
  }, []);

  return (
    <div className='bg flex flex-row' style={{ height: '100vh' }}>
      <div>
        <SideBarKaonty />
      </div>
      <div className='bg w-full' style={{ background: '#F9F9FC' }}>
        <div>
          <Header titre={'Abonnement'}/>
        </div>
        <div className='pl-8 pr-7 py-6'>
          <div className='j justify-between'>
            <div className='space-x-3'>
              <span onClick={revenir}><LeftCircleOutlined style={{ fontSize: '25px', color: '#0071BD' }} className='c cursor-pointer'/></span>
              <span className='text-lg font-semibold'>Détails d'une demande</span>
            </div>
          </div>

          <div className="border-2 mt-8 p-7 rounded-2xl shadow-lg bg-white">
  {loading ? (
     <div className="flex justify-center items-center">
      <div className="w-12 h-12 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
    </div>
  ) : (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6">
      {/* Message */}
      <div className="space-y-2">
        <p className="font-semibold text-gray-700">Message</p>
        <p className="text-gray-600">{data.message}</p>
      </div>
      
      {/* Objet */}
      <div className="space-y-2">
        <p className="font-semibold text-gray-700">Objet</p>
        <p className="text-gray-600">{data.objet}</p>
      </div>
      
      {/* Type */}
      <div className="space-y-2">
        <p className="font-semibold text-gray-700">Type</p>
        <p className="text-gray-600">{data.type}</p>
      </div>
      
      {/* Choix et Prix */}
      <div className="grid grid-cols-2 gap-4">
        <div className="space-y-2">
          <p className="font-semibold text-gray-700">Choix</p>
          <p className="text-gray-600">{data.choix}</p>
        </div>
        <div className="space-y-2">
          <p className="font-semibold text-gray-700">Prix</p>
          <p className="text-gray-600">
            {parseInt(data.prix).toLocaleString('fr-FR')} Ar
          </p>
        </div>
      </div>
      
      {/* Date */}
      <div className="space-y-2">
        <p className="font-semibold text-gray-700">Date</p>
        <p className="text-gray-600">
          {new Date(data.date).toLocaleDateString('fr-FR', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          })}
        </p>
      </div>
      
      {/* Etat */}
      <div className="space-y-2">
        <p className="font-semibold text-gray-700">État</p>
        <p
          className={`font-semibold ${
            data.etat === 'ACCEPTEE'
              ? 'text-blue-500'
              : data.etat === 'REFUSEE'
              ? 'text-red-500'
              : 'text-gray-600'
          }`}
        >
          {data.etat}
        </p>
      </div>
      
      {/* Référence */}
      <div className="space-y-2">
        <p className="font-semibold text-gray-700">Référence de paiement</p>
        <p className="text-gray-600">{data.ref}</p>
      </div>
      
      {/* Capture d'écran */}
      <div className="space-y-2">
        <p className="font-semibold text-gray-700">Capture d'écran du paiement</p>
        <button
          onClick={() => handleImageClick(data.image)}
          className="px-4 py-2 font-semibold text-blue-600 bg-white border border-blue-600 rounded-lg hover:bg-blue-600 hover:text-white transition duration-300"
        >
          Voir l'image
        </button>
      </div>
    </div>
  )}

  {/* Boutons */}
  {/* <div className="flex justify-end mt-8 space-x-4">
    <button className="px-4 py-2 font-semibold text-green-600 bg-white border border-green-600 rounded-lg hover:bg-green-600 hover:text-white transition duration-300">
      Accepter
    </button>
    <button className="px-4 py-2 font-semibold text-red-600 bg-white border border-red-600 rounded-lg hover:bg-red-600 hover:text-white transition duration-300">
      Refuser
    </button>
  </div> */}
</div>


          

        </div>
      </div>

      {/* Modal pour afficher l'image */}
      {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white p-4 rounded-lg shadow-lg max-w-lg w-full">
              <div className='flex justify-between'>
                {/* <h2 className="text-lg font-semibold mb-4">Image du capture d'écran du paiement</h2> */}
                <h2></h2>
                <div>
                  {/* <button
                    onClick={toggleModal}
                    className="px-4 py-1 bg-red-500 text-white rounded-lg hover:bg-red-600"
                  >
                    Fermer
                  </button> */}
                  <CloseOutlined className='border rounded-xl cursor-pointer bg-red-400 text-white hover:bg-red-500 p-2 mb-3' onClick={toggleModal}/>
                </div>
              </div>
              <img src={api + '/storage/' + data.image} alt="Image de la demande" className="w-full h-auto mb-4" />
              
            </div>
          </div>
        )}

        {/* Modal d'affichage */}
      {isModalVisibleImg && (
        <ModalImage
          imageUrl={imageToShow}
          visible={isModalVisibleImg}
          onClose={closeModal}
        />
      )}
      
    </div>
  )
}

export default DetailDemande

