import React, { useEffect, useState } from 'react'
import SideBar from '../../../Components/SuperAdmin/SideBar'
import Header from '../../../Components/SuperAdmin/Header'
import { DownOutlined,EllipsisOutlined, FilterOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import {Modal} from 'antd'
import { api } from '../../../api';
import axios from 'axios';
import { Button, message as antdMessage } from 'antd';

const Abonnement = () => {

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const { confirm } = Modal;
  const [messageApi, contextHolder] = antdMessage.useMessage();
  const successExpirer = () => {
    messageApi.open({
      type: 'success',
      content: 'Abonnement expiré avec succès !',
    });
  };

  const successSignal = () => {
    messageApi.open({
      type: 'success',
      content: 'Email envoyé avec succès !',
    });
  };

  const navigate = useNavigate()

  // Fonction pour rediriger vers la page des détails
  // const detailUtilisateur = (id) => {
  //   navigate(`/superAdmin/utilisateur/${id}`);
  // };

  const [objet, setObjet] = useState('')
  const [message, setMessage] = useState('')
  const [email, setEmail] = useState('')

  const showModal = (email) => {
    setOpen(true);
    setEmail(email)
  };

  const handleOk = () => {
    setConfirmLoading(true);
    emailSignal(email, objet, message)
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    // console.log('Clicked cancel button');
    setOpen(false);
  };

  // Fonction pour rediriger vers la page des détails
  const  detailDemande = (demande_id) => {
    navigate(`/superAdmin/demande/${demande_id}`);
  };

  const items = (id, nomClient, prenomClient, demande_id, etat, email) => [
    {
      label: <p className='f font-semibold hover:text-primary' onClick={() => detailDemande(demande_id)}>Voir la demande</p>,
      key: '0',
    },
    {
      label: <p className='f font-semibold hover:text-primary' onClick={() => showModal(email)}>Signaler</p>,
      key: '1',
    },
    // Afficher "Expirer" uniquement si l'état est "EN COURS"
    ...(etat == 'EN COURS' ? [{
      label: <p className='f font-semibold hover:text-red-600' onClick={() => showConfirmExpirer(id, nomClient, prenomClient, email)}>Expirer</p>,
      key: '2',
    }] : []),
    // {
    //   label: <p className='f font-semibold hover:text-red-600' onClick={() => showConfirmExpirer(id, nomClient, prenomClient)}>Expirer</p>,
    //   key: '2',
    // },
    
  ];

  // const data = [
  //   { id: 1, type: 'Standard', choix: 'mensuel', prix: '55 000 Ar', nomClient: 'RANDRIANASOLO Assil', dateFin: '22 octobre 2024', etat: 'En cours', demande_id:1 },
  //   { id: 2, type: 'Standard', choix: 'mensuel', prix: '55 000 Ar', nomClient: 'RANDRIANASOLO Assil', dateFin: '22 octobre 2024', etat: 'En cours', demande_id:2 },
  //   { id: 3, type: 'Standard', choix: 'annuel', prix: '600 000 Ar', nomClient: 'RANDRIANASOLO Assil', dateFin: '22 octobre 2025', etat: 'Expiré', demande_id:3 },
  //   { id: 4, type: 'Standard', choix: 'mensuel', prix: '55 000 Ar', nomClient: 'RANDRIANASOLO Assil', dateFin: '22 octobre 2024', etat: 'En cours', demande_id:4 },
  //   { id: 5, type: 'Standard', choix: 'annuel', prix: '600 000 Ar', nomClient: 'RANDRIANASOLO Assil', dateFin: '22 octobre 2025', etat: 'En cours', demande_id:5 },
  //   { id: 6, type: 'Standard', choix: 'mensuel', prix: '55 000 Ar', nomClient: 'RANDRIANASOLO Assil', dateFin: '22 octobre 2024', etat: 'Expiré', demande_id:6 },
  //   { id: 7, type: 'Standard', choix: 'mensuel', prix: '55 000 Ar', nomClient: 'RANDRIANASOLO Assil', dateFin: '22 octobre 2024', etat: 'En cours', demande_id:7 },
  //   { id: 8, type: 'Standard', choix: 'mensuel', prix: '55 000 Ar', nomClient: 'RANDRIANASOLO Assil', dateFin: '22 octobre 2024', etat: 'En cours', demande_id:8 },
  //   { id: 9, type: 'Standard', choix: 'mensuel', prix: '55 000 Ar', nomClient: 'RANDRIANASOLO Assil', dateFin: '22 octobre 2024', etat: 'En cours', demande_id:9 },
  //   { id: 10, type: 'Standard', choix: 'mensuel', prix: '55 000 Ar', nomClient: 'RANDRIANASOLO Assil', dateFin: '22 octobre 2024', etat: 'En cours', demande_id:10 },
  // ];

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true);

  // Tous les demandes
  const getAllAbonnements = async () => {
    try {
      const response = await axios.get(api + `/api/allAbonnement`);
      setData(response.data);
      setLoading(false);

    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  useEffect(() => {
    getAllAbonnements();
  }, []);

  // État pour le filtre
  const [filterEtat, setFilterEtat] = useState('');

  // Filtrage des données selon la valeur du filtre
  const filteredData = filterEtat
    ? data.filter(abonnement => abonnement.etat === filterEtat)
    : data;

  // Envoyer un email d'expiration
  const emailExpiration = async (email) => {
    try {
      const response = await axios.post(api + `/api/expirerAbonnement`, {email});
      // setData(response.data);
      console.log("E-mail envoyé avec succès")
      // successExpirer();
      // getAllAbonnements()

    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  // Envoyer un email d'expiration
  const emailSignal = async (email, objet, message) => {
    try {
      const response = await axios.post(api + `/api/signalerUtilisateur`, {email, objet, message});
      // setData(response.data);
      console.log("E-mail envoyé avec succès")
      successSignal()
      // successExpirer();
      // getAllAbonnements()

    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

    // Expirer une abonnement
  const expirerAbonnement = async (id) => {
    try {
      const response = await axios.post(api + `/api/expirerAbonnement/${id}`);
      // setData(response.data);
      successExpirer();
      getAllAbonnements()
      

    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };


  // Modal de confirmation d'expiration d'un abonnement
  const showConfirmExpirer = (id, nomClient, prenomClient, email) => {
    confirm({
      title: 'Voulez-vous vraiment expirer ce abonnement de ' + nomClient + ' ' + prenomClient + ' ?',
      icon: <ExclamationCircleFilled />,
      content: (
        <div>
          {/* <p>Code: {journal.id}</p>
          <p>Description: {journal.abreviation}</p>
          <p>Description: {journal.libelle}</p> */}
          {/* <p>Contenu du modal</p> */}
        </div>
      ),
      okText: 'Oui, expirer', // Texte personnalisé pour le bouton OK
      cancelText: 'Non, annuler',
      okButtonProps: {
        style: { backgroundColor: 'primary', borderColor: 'primary', color: 'white' }, // Bouton OK en rouge
      },
      onOk() {
        // console.log('Supprimer');
        // alert('Expiré')
        expirerAbonnement(id)
        emailExpiration(email)
      },
      onCancel() {
        // alert('Annulé')
      },
    });
  };

  return (
    <div className='bg flex flex-row' style={{ height: '100vh' }}>
      {contextHolder}
      {/* style={{ background: '#F9F9FC' }} */}
      <div>
        <SideBar />
      </div>
      <div className='bg w-full' style={{ background: '#F9F9FC' }}>
        <div>
          <Header titre={'Abonnement'}/>
        </div>
        <div className='pl-8 pr-7 py-4'>

          {/* Filtre */}
          {/* <div className='flex space-x-10 mt-3'>
            <div>
              <span className='font-semibold text-lg'>Filtre <span><FilterOutlined /></span> :</span>
            </div>
            <select value={filterEtat} onChange={(e) => setFilterEtat(e.target.value)} className='border px-1 border-primary rounded-md'>
              <option value="">-- Tout afficher --</option>
              <option value="EN COURS">EN COURS</option>
              <option value="EXPIRE">EXPIRE</option>
            </select>
          </div> */}

          <div className="flex items-center space-x-6 mt-1 bg-gray-50 rounded-lg shadow-sm">
            
            <div className='relative'>
            <span className="absolute inset-y-0 left-3 flex items-center text-gray-400">
                <FilterOutlined className='text-primary'/>
              </span>
            <select 
              value={filterEtat} 
              onChange={(e) => setFilterEtat(e.target.value)} 
              className="border border-gray-300 rounded-md pl-8 pr-2 px-3 py-1 text-gray-700 focus:outline-none cursor-pointer focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="">-- Tout afficher --</option>
              <option value="EN COURS">EN COURS</option>
              <option value="EXPIRE">EXPIRE</option>
            </select>
            </div>
          </div>


            {/* Table */}
        {/* <div className="overflow-x-auto mt-5 rounded-xl">
            <table className="min-w-full bg-white border border-gray-200">
              <thead>
                <tr className="bg-gray-300 border-b">
                  <th className="py-2 px-4 text-left">Type</th>
                  <th className="py-2 px-4 text-left">Choix</th>
                  <th className="py-2 px-4 text-left">Prix (Ar)</th>
                  <th className="py-2 px-4 text-left">Nom du client</th>
                  <th className="py-2 px-4 text-left">Date d'expiration</th>
                  <th className="py-2 px-4 text-left">Etat</th>
                  <th className="py-2 px-4 text-left flex justify-center items-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {
                  loading ? (
                      // Spinner de chargement avec TailwindCSS
                      <>
                      <tr>
                        <td colSpan='7'>
                          <div className="flex justify-center items-center h-32">
                            <div className="w-10 h-10 border-4 border-blue-500 border-dashed rounded-full animate-spin"></div>
                          </div>
                        </td>
                      </tr>
                      </>
                  ) : (
                    <>
                      {
                        data.length === 0 ? (
                            <tr>
                              <td colSpan="7" className="text-center py-4">
                                <div>Aucune donnée disponible</div>
                              </td>
                            </tr>
                        ) : (
                          filteredData.map((abonnement) => (
                            <tr key={abonnement.id} className="border-b">
                              <td className="py-2 px-4">{abonnement.type}</td>
                              <td className="py-2 px-4">{abonnement.choix}</td>
                              <td className="py-2 px-4">{(abonnement.prix).toLocaleString('fr-FR')}</td>
                              <td className="py-2 px-4">{abonnement.user.nom} {abonnement.user.prenom}</td>
                              <td className="py-2 px-4">{abonnement.dateFin}</td>
                              <td className={`py-2 px-4
                                  ${abonnement.etat === 'EN COURS' ? 'text-blue-500' : ''} 
                                  ${abonnement.etat === 'EXPIRE' ? 'text-red-600' : ''}`}>
                                <div className={`border inline-block px-2 rounded-full ${abonnement.etat === 'EN COURS' ? 'border-primary' : ''}
                                ${abonnement.etat === 'EXPIRE' ? 'border-red-600' : ''}`}>
                                  {abonnement.etat}
                                </div>
                              </td>
                              <td className="py-2 px-4 space-x-4 flex justify-center items-center">
                                  <Dropdown
                                    menu={{
                                      items: items(abonnement.id, abonnement.user?.nom, abonnement.user?.prenom, abonnement.demande_id, abonnement.etat),
                                    }}
                                    trigger={['click']}
                                  >
                                    <a
                                      onClick={(e) => e.preventDefault()}
                                      className="text-gray-800 font-medium flex items-center space-x-2 hover:text-blue-600"
                                    >
                                      <Space>
                                        
                                        <EllipsisOutlined style={{ fontSize: '25px', color: '#0071BD' }} className='c cursor-pointer'/>
                                      </Space>
                                    </a>
                                  </Dropdown>
                              </td>
                            </tr>
                          ))
                        )
                      }
                    </>
                  )
                }
               
              </tbody>
            </table>
          </div> */}

<div className="overflow-x-auto mt-5 rounded-xl shadow-lg">
  <table className="min-w-full bg-white border border-gray-200 text-sm text-gray-700">
    <thead>
      <tr className="bg-blue-100 font-medium text-gray-700 border-b">
        <th className="py-3 px-4 text-left">Type</th>
        <th className="py-3 px-4 text-left">Choix</th>
        <th className="py-3 px-4 text-left">Prix (Ar)</th>
        <th className="py-3 px-4 text-left">Nom du client</th>
        <th className="py-3 px-4 text-left">Date d'expiration</th>
        <th className="py-3 px-4 text-left">Etat</th>
        <th className="py-3 px-4 text-center">Actions</th>
      </tr>
    </thead>
    <tbody>
      {
        loading ? (
          <tr>
            <td colSpan="7" className="text-center py-6">
                    <div className="flex justify-center items-center">
                      <div className="w-12 h-12 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
                    </div>
            </td>
          </tr>
        ) : (
          data.length === 0 ? (
            <tr>
              <td colSpan="7" className="text-center py-6">
                <span className="text-gray-500">Aucune donnée disponible</span>
              </td>
            </tr>
          ) : (
            filteredData.map((abonnement, index) => (
              <tr 
                key={abonnement.id} 
                className={`border-b hover:bg-gray-100 ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}`}
              >
                <td className="py-2 px-4">{abonnement.type}</td>
                <td className="py-2 px-4">{abonnement.choix}</td>
                <td className="py-2 px-4">{(abonnement.prix).toLocaleString('fr-FR')}</td>
                <td className="py-2 px-4">{abonnement.user.nom} {abonnement.user.prenom}</td>
                <td className="py-2 px-4">{abonnement.dateFin}</td>
                <td 
                  className={`py-2 px-4 text-left font-medium 
                    ${abonnement.etat === 'EN COURS' ? 'text-blue-500' : ''}
                    ${abonnement.etat === 'EXPIRE' ? 'text-red-600' : ''}`}
                >
                  <div 
                    className={`border px-2 rounded-full inline-block 
                      ${abonnement.etat === 'EN COURS' ? 'border-blue-500 bg-blue-50' : ''}
                      ${abonnement.etat === 'EXPIRE' ? 'border-red-600 bg-red-50' : ''}`}
                  >
                    {abonnement.etat}
                  </div>
                </td>
                <td className="py-2 px-4 text-center">
                  <Dropdown
                    menu={{
                      items: items(
                        abonnement.id, 
                        abonnement.user?.nom, 
                        abonnement.user?.prenom, 
                        abonnement.demande_id, 
                        abonnement.etat,
                        abonnement.user?.email
                      ),
                    }}
                    trigger={['click']}
                  >
                    <a
                      onClick={(e) => e.preventDefault()}
                      className="text-gray-800 font-medium hover:text-blue-600"
                    >
                      <Space>
                        <EllipsisOutlined style={{ fontSize: '25px', color: '#0071BD' }} className="cursor-pointer" />
                      </Space>
                    </a>
                  </Dropdown>
                </td>
              </tr>
            ))
          )
        )
      }
    </tbody>
  </table>
</div>


        </div>

      </div>

      {/* Modal pour signaler un client par email */}
      <Modal title="Signaler un client par email" open={open} onOk={handleOk} confirmLoading={confirmLoading} onCancel={handleCancel} footer={(
              <div className="flex justify-center space-x-4">
                <button 
                  onClick={handleCancel} 
                  className="px-6 py-2 bg-gray-200 text-black rounded hover:bg-gray-300"
                >
                  Annuler
                </button>
                <button 
                  onClick={handleOk} 
                  className="px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  disabled={confirmLoading}
                >
                  {confirmLoading ? 'En cours...' : 'Envoyer'}
                </button>
              </div>
            )}
          >

                <div className='space-y-2 mt-4 mb-2'>
                  <label className="font-semibold">Destinataire</label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    // autoComplete="email"
                    value={email}
                    // onChange={(e) => setEmail(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Entrez l'objet"
                    disabled
                  />
                </div>  

                <div className='space-y-2 mt-4 mb-4'>
                  <label className="font-semibold">Objet</label>
                  <input
                    id="objet"
                    name="objet"
                    type="text"
                    // autoComplete="email"
                    required
                    onChange={(e) => setObjet(e.target.value)}
                    value={objet}
                    // onChange={(e) => setEmail(e.target.value)}
                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Entrez l'objet"
                  />
                </div>

                <div className='space-y-2 mt-4 mb-8'>
                  <label className="font-semibold">Message</label>
                  <textarea
                    id="message"
                    name="message"
                    type="text"
                    // autoComplete="email"
                    required
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                    // onChange={(e) => setEmail(e.target.value)}
                    className="appearance-none relative block w-full px-3 h-24 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Entrez le message"
                  />
                </div>
      </Modal>
      
    </div>
  )
}

export default Abonnement

