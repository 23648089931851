import React, { useEffect, useState } from 'react'
import { Button, message, Space, Select, Spin } from 'antd';
import {Modal} from 'antd'
import {EditOutlined, DeleteOutlined, ExclamationCircleFilled, CheckCircleFilled} from '@ant-design/icons'
import { api } from '../api';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ContenuDemande = () => {

  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();

  const [type, setType] = useState('Standard')
  const [prix, setPrix] = useState(55000)
  const [choix, setChoix] = useState('Mensuel')
  const [objet, setObjet] = useState('')
  const [messageAbo, setMessageAbo] = useState('')
  const [ref, setRef] = useState('')
  const [image, setImage] = useState('')

  const [messageApi, contextHolder] = message.useMessage();

  const user_id = sessionStorage.getItem('user_id');

   // Selectionner un choix
   const selectChoix = (value) => {
    setChoix(value)

    if (value == 'Mensuel')
    {
      setPrix(55000)
    } else {
      setPrix(600000)
    }
  };

  const infoIncomplete = () => {
    messageApi.open({
      type: 'info',
      content: 'Les champs objet, message, référence sont obligatoire, veuillez les vérifier.',
    });
  };

  // const choixPrix = () => {
  //   if (choix == 'Mensuel')
  //   {
  //     setPrix(55000)
  //   } else {
  //     setPrix(600000)
  //   }
  // }

  // useEffect(() => {
  //   choixPrix()
  // }, [])

  const verificationFormulaire = () => {
    if (objet == '')
    {
      infoIncomplete()
    } else {
      showConfirmEnvoyer()
    }
      
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file); // Mettre à jour l'image sélectionnée
      
      // console.log(file)
    }
  };

  const logout = async () => {
    try {
      const response = await axios.post(api + '/api/deconnexion', {}, {
        headers: {
          Authorization: `Bearer ${token}`, // Assurez-vous que vous envoyez le bon token
        },
      });
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('user_id');
      sessionStorage.removeItem('admin_id');
      navigate('/connexion')
      console.log(response.data);

    } catch (error) {
      console.error(error);
    }
  };

  const { confirm } = Modal;

  // Modal de confirmation d'ajout
  const showConfirmEnvoyer = () => {
    confirm({
      title: 'Voulez-vous vraiment envoyer cette demande d\'abonnement ?',
      icon: <ExclamationCircleFilled />,
      content: (
        <div>
          {/* <p>Code: {journal.id}</p>
          <p>Description: {journal.abreviation}</p>
          <p>Description: {journal.libelle}</p> */}
          {/* <p>Contenu du modal</p> */}
        </div>
      ),
      okText: 'Oui, envoyer', // Texte personnalisé pour le bouton OK
      cancelText: 'Non, annuler',
      okButtonProps: {
        style: { backgroundColor: 'primary', borderColor: 'primary', color: 'white' }, // Bouton OK en rouge
      },
      onOk() {

        // console.log(objet + '' + messageAbo + '' + type + '' + choix + '' + prix + '' + ref + '' + image )
        try {
          const form = new FormData()
          form.append("objet", objet)
          form.append("message", messageAbo)
          form.append("type", type)
          form.append("choix", choix)
          form.append("prix", prix)
          form.append("ref", ref)
          
          if (image)
          {
            form.append("image", image)
          }

          axios.post(api + '/api/ajoutDemande/' + user_id, form)
              .then(res => {
                  console.log(res.data);
                  setObjet(''); setMessageAbo(''); setType('Standard'); setChoix('Mensuel'); setPrix(55000); setRef(''); setImage('');
                  showConfirmAjout()
                  
                  // Feedback succes
              })
              .catch(err => {
                  console.error(err);
              })
      } catch (error) {
          console.error(error)
      }
        
        
      },
      onCancel() {
        // alert('Annulé')
      },
    });
  };

  // Modal de validation
  const showConfirmAjout = () => {
    confirm({
      title: 'Votre demande d\'abonnement a été effectué avec succès. \n \n Vous recevrez un email si votre demande sera acceptée ou non.',
      icon: <CheckCircleFilled />,
      content: (
        <div>
          {/* <p>Code: {journal.id}</p>
          <p>Description: {journal.abreviation}</p>
          <p>Description: {journal.libelle}</p> */}
          {/* <p>Contenu du modal</p> */}
        </div>
      ),
      okText: 'Ok', // Texte personnalisé pour le bouton OK
      cancelText: 'Retour',
      okButtonProps: {
        style: { backgroundColor: 'primary', borderColor: 'primary', color: 'white' }, // Bouton OK en rouge
      },
      onOk() {
        logout()
      },
      onCancel() {
        logout()
      },
    });
  };
  

  const { Option } = Select
  return (
    <div className='m mt-28 md:px-24 mx-auto grid grid-cols-1 md:grid-cols-1'>
             {contextHolder}
      <div className='mb-5'>
        <span className='text-lg'>Sur cette page, vous pouvez soumettre une demande d'abonnement en précisant l'objet de la demande, le message, la référence de votre paiement ainsi que les autres informations à remplir.<br />
           Le paiement peut être effectué par Mobile Money via Telma au numéro <span className='text-primary font-semibold'>034 46 403 21</span> ou Orange au numéro <span className='text-primary font-semibold'>032 67 456 78</span>. <br />
           Veuillez remplir tous les champs avec précision pour assurer le bon traitement de votre demande.</span>
      </div>
      <div className='grid grid-cols-2 md:grid-cols-2 gap-x-10 gap-y-5'>
        <div className='space-y-3'> 
          <span className='text-lg'>Objet</span>
          <textarea
                    id="objet"
                    name="objet"
                    type="text"
                    required
                    value={objet}
                    onChange={(e) => setObjet(e.target.value)}
                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Votre objet"
          />
        </div>
        <div className='space-y-3'>
          <span className='text-lg'>Message</span>
          <textarea
                    id="messageAbo"
                    name="messageAbo"
                    type="text"
                    required
                    value={messageAbo}
                    onChange={(e) => setMessageAbo(e.target.value)}
                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Votre message"
          />
        </div>
        <div className='space-y-3'>
          <span className='text-lg'>Type</span>
          <input
                    id="type"
                    name="type"
                    type="text"
                    required
                    value={type}
                    // onChange={handleInputChange}
                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    disabled
          />
        </div>
        <div className='grid grid-cols-2 md:grid-cols-2 gap-x-7'>
          <div className='space-y-3'>
              <span className='text-lg'>Choix</span>
                    <Select placeholder="Votre choix" className='block' style={{ width: 270, borderColor: 'red', height: '38px' }} onChange={selectChoix} value={choix}>
                            <Option value="Mensuel">
                              Mensuel
                            </Option>
                            <Option value="Annuel">
                              Annuel
                            </Option>
                    </Select>
          </div>
          <div className='space-y-3'>
              <span className='text-lg'>Prix (Ar)</span>
              <input
                        id="message"  
                        name="message"
                        type="text"
                        required
                        value={prix.toLocaleString('fr-FR')}
                        // onChange={handleInputChange}
                        className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                        // placeholder="Votre message"
                        disabled
              />
          </div>
        </div>
        <div className='space-y-3'>
          <span className='text-lg'>Référence</span>
          <input
                    id="ref"
                    name="ref"
                    type="text"
                    required
                    value={ref}
                    onChange={(e) => setRef(e.target.value)}
                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    
          />
        </div>
        <div className='space-y-3'>
          <span className='text-lg'>Image de capture d'écran du paiement</span>
          <input
                    id="ref"
                    name="ref"
                    type="file"
                    required
                    // value={image}
                    onChange={handleFileChange}
                    className="appearance-none relative block w-full px-3 py-[5px] border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    
          />
        </div>

        <div className='space-y-3'>
          
        </div>
        <div className='space-y-3 flex justify-between'>
                <div>
                </div>
                <div onClick={verificationFormulaire}>
                 <button className='px-4 py-1 font-semibold bg-white text-primary duration-300 border-primary rounded-lg border-2 hover:text-white hover:bg-primary'>Envoyer</button>
                </div>
        </div>
      </div>
    </div>
  )
}

export default ContenuDemande