import React, { useState } from 'react';
import { FaXmark } from 'react-icons/fa6';
// import { GrLanguage } from "react-icons/gr";
import { HiMiniBars4 } from "react-icons/hi2";
import { useNavigate } from 'react-router-dom';
import Logo from "../Assets/Logo.png"
import { Link } from 'react-scroll'

const NavBar = () => {
  const navigate = useNavigate();
  
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  }

  const handleLoginClick = () => {
    navigate('/connexion');
  }

  const navItems = [
    { link: "Accueil", path: "accueil" },
    { link: "A propos", path: "Apropos" },
    { link: "Fonctionnalités", path: "fonctionnalite" },
    { link: "Abonnement", path: "abonnement" },
  ];

  return (
    <>

    
      <nav className='b bg-white md:px-14 p-4 max-w-screen-2xl mx-auto fixed top-0 right-0 left-0' style={{zIndex: 10001}}>
        <div className='text-lg container mx-auto flex justify-between items-center font-medium'>
          <div className='flex space-x-14 items-center'>
            <a href="/" className='b'><img src={Logo} alt="" className='inline-block w-32 items-center -translate-x-5' /></a>
            <ul className='md:flex space-x-12 hidden cursor-pointer'>
              {
                navItems.map(({ link, path }) => <Link spy={true} smooth={true} offset={-80} key={link} to={path} className='block hover:text-primary'>{link}</Link>)
              }
            </ul>
          </div>
        
          <div className='space-x-12 hidden md:flex items-center'>
            {/* <a href="/" className='hidden lg:flex items-center space-x-2 hover:text-secondary'><GrLanguage />Language</a> */}
            <button className='bg-primary py-1 px-4 transition-all duration-300 rounded hover:text-white hover:bg-blue-700 text-white lg:text-lg' onClick={handleLoginClick}>Se connecter</button>
          </div>
          <div className='md:hidden'>
            <button onClick={toggleMenu} className='text-white focus:outline-none focus:text-gray-300'>
              {
                isMenuOpen ? (<FaXmark className='w-6 h-6 text-primary'/>) : (<HiMiniBars4 className='w-6 h-6 text-primary'/>)
              }
            </button>
          </div>
        </div>
      </nav>

      <div className={`space-y-4 px-4 pt-24 pb-5 bg-primary text-white ${isMenuOpen ? "block fixed top-0 right-0 left-0" : "hidden"}`}>
              {
                navItems.map(({ link, path }) => <a key={link} href={path} className='block hover:text-gray-300'>{link}</a>)
              }
      </div>
    </>
  );
};

export default NavBar;
