import React from 'react'
import {Menu} from 'antd'
import {HomeOutlined, FormOutlined, FileTextOutlined, UserOutlined} from '@ant-design/icons'
import { EditOutlined, TeamOutlined, SettingOutlined, DollarOutlined, SolutionOutlined, HistoryOutlined} from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

const MenuList = () => {

  const navigate = useNavigate()

  const toDashboard = () => {
    navigate('/superAdmin/')
  }

  const toCodeJournale = () => {
    navigate('/superAdmin/codeJournale')
  }

  const toPlanComptable = () => {
    navigate('/superAdmin/planComptable')
  }

  const toUtilisateur = () => {
    navigate('/superAdmin/utilisateur')
  }

  const toAbonnement = () => {
    navigate('/superAdmin/abonnement')
  }

  const toDemande = () => {
    navigate('/superAdmin/demande')
  }

  const toHistorique = () => {
    navigate('/superAdmin/historique')
  }

  const toProfil = () => {
    navigate('/superAdmin/profil')
  }

  return (
    <Menu theme='light' className='pl-2 mt-3 h-[88vh] flex flex-col gap-[5px]' mode='inline'>
      <Menu.Item onClick={toDashboard} style={{ color: '#949494' }}  className='s font-semibold' key="Tableau-bord" icon={<HomeOutlined style={{ color: '#0071BD' }}/>}>
        Tableau de bord
      </Menu.Item>

      {/* <Menu.Item onClick={toCodeJournale} style={{ color: '#949494' }}  className='s font-semibold' key="Journale-saisie" icon={<FormOutlined style={{ color: '#0071BD' }}/>}>
        Code journal
      </Menu.Item>

      <Menu.Item onClick={toPlanComptable} style={{ color: '#949494' }}  className='s font-semibold' key="Plan-comptable" icon={<FileTextOutlined style={{ color: '#0071BD' }}/>}>
        Plan comptable
      </Menu.Item> */}

      <Menu.Item onClick={toUtilisateur} style={{ color: '#949494' }}  className='s font-semibold' key="Journale-saisie" icon={<TeamOutlined style={{ color: '#0071BD' }}/>}>
        Utilisateur
      </Menu.Item>

      <Menu.Item onClick={toAbonnement} style={{ color: '#949494' }}  className='s font-semibold' key="Plan-comptable" icon={<DollarOutlined style={{ color: '#0071BD' }}/>}>
        Abonnement
      </Menu.Item>

      <Menu.Item onClick={toDemande} style={{ color: '#949494' }}  className='s font-semibold' key="Code-journal" icon={<SolutionOutlined style={{ color: '#0071BD' }}/>}>
         Demande
      </Menu.Item>

      <Menu.Item onClick={toHistorique} style={{ color: '#949494' }}  className='s font-semibold' key="Annee-fiscale" icon={<HistoryOutlined style={{ color: '#0071BD' }}/>}>
         Historique
      </Menu.Item>

      <Menu.Item onClick={toProfil} style={{ color: '#949494' }}  className='s font-semibold' key="Profil" icon={<UserOutlined style={{ color: '#0071BD' }}/>}>
        Profil
      </Menu.Item>

      {/* <Menu.SubMenu style={{ color: '#949494' }}  className='s font-semibold' key="Parametre" icon={<SettingOutlined style={{ color: '#0071BD' }}/>} title={<span style={{ color: '#949494' }}>Paramètre</span>}>
         <Menu.Item onClick={toEntite} key="Entité" className=''>Entité</Menu.Item>
         <Menu.Item onClick={toProfil} key="Profil">Profil</Menu.Item>
      </Menu.SubMenu> */}
    </Menu>
  )
}

export default MenuList