import React, { useEffect, useState } from 'react'

import SideBar from '../../../Components/SuperAdmin/SideBar'
import Header from '../../../Components/SuperAdmin/Header'
import { UserOutlined, ClockCircleOutlined, CheckCircleOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { api } from '../../../api';
import axios from 'axios';
import ChartCercle from '../Charts/ChartCercle';
import BarChart from '../Charts/BarChart';

const Dashboard = () => {

    const token = sessionStorage.getItem('token');
  const [userInfo, setUserInfo] = useState(null);
  const [user, setUser] = useState(0);
  const [demandeEnAttente, setDemandeEnAttente] = useState(0);
  const [demandeAcceptee, setDemandeAcceptee] = useState(0);
  const [abonnee, setAbonnee] = useState(0);

  const prenomUser = sessionStorage.getItem('prenomUser');

  // const user_id = sessionStorage.getItem('user_id');
  // const token = sessionStorage.getItem('token');
  const [nom, setNom] = useState('.....');

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axios.get(api + '/api/user', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserInfo(response.data);
        setNom(response.data.prenom);
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    const getTotalNombre = async () => {
      try {
        const response = await axios.get(api + '/api/getTotalDashboardAdmin');
        setUser(response.data.totalUsers);
        setDemandeEnAttente(response.data.totalDemandesEnAttente);
        setDemandeAcceptee(response.data.totalDemandesAccepte);
        setAbonnee(response.data.totalAbonnement);
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    getTotalNombre()

    fetchUserInfo();
  }, [token]);

  return (
    <div className='bg flex flex-row' style={{ height: '100vh' }}>
      <div>
        <SideBar />
      </div>
      <div className='bg w-full' style={{ background: '#F9F9FC' }}>
        <Header titre={'Bonjour ' + prenomUser}/>

        <div className='w-full grid grid-cols-4 justify-start items-start gap-x-6 px-7 pt-6'>
                <div className='w-full p-3 flex flex-row justify-between items-start bg-white border rounded-lg border-l-primary border-l-4'>
                    <div className='flex flex-col gap-y-1'>
                        <p className='font-semibold text-lg'>Utilisateur</p>
                        {/* <h4 className='text-3xl'>{user.length}</h4> */}
                        <h4 className='text-lg'>3</h4>
                    </div>
                    <div className='p-3 bg-redColor/10 rounded-full h-full border border-redColor text-redColor'>
                        {/* <FaUsers /> */}
                        <UserOutlined style={{ fontSize: '28px', color: '#1890ff' }} />
                    </div>
                </div>
                <div className='w-full p-3 flex flex-row justify-between items-start bg-white border rounded-lg border-l-primary border-l-4'>
                    <div className='flex flex-col gap-y-1'>
                        <p className='font-semibold text-lg'>Demande en attente</p>
                        {/* <h4 className='text-3xl'>{user.length}</h4> */}
                        <h4 className='text-lg'>2</h4>
                    </div>
                    <div className='p-3 bg-redColor/10 rounded-full h-full border border-redColor text-redColor'>
                        {/* <FaUsers /> */}
                        <ClockCircleOutlined style={{ fontSize: '28px', color: '#1890ff' }} />
                    </div>
                </div>
                <div className='w-full p-3 flex flex-row justify-between items-start bg-white border rounded-lg border-l-primary border-l-4'>
                    <div className='flex flex-col gap-y-1'>
                        <p className='font-semibold text-lg'>Demande acceptée</p>
                        {/* <h4 className='text-3xl'>{user.length}</h4> */}
                        <h4 className='text-lg'>3</h4>
                    </div>
                    <div className='p-3 bg-redColor/10 rounded-full h-full border border-redColor text-redColor'>
                        {/* <FaUsers /> */}
                        <CheckCircleOutlined style={{ fontSize: '28px', color: '#1890ff' }} />
                    </div>
                </div>
                <div className='w-full p-3 flex flex-row justify-between items-start bg-white border rounded-lg border-l-primary border-l-4'>
                    <div className='flex flex-col gap-y-1'>
                        <p className='font-semibold text-lg'>Abonnées</p>
                        {/* <h4 className='text-3xl'>{user.length}</h4> */}
                        <h4 className='text-lg'>3</h4>
                    </div>
                    <div className='p-3 bg-redColor/10 rounded-full h-full border border-redColor text-redColor'>
                        {/* <FaUsers /> */}
                        <UsergroupAddOutlined style={{ fontSize: '28px', color: '#1890ff' }} />
                    </div>
                </div>

        </div>

        <div className='mt-6 px-5 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-8 py-4 w-full'>

          <div className='flex flex-col items-center justify-center'>
            <h1 className='text-center font-semibold mb-5'>STATISTIQUE D'ABONNEMENTS</h1>
            <div className='flex items-center justify-center w-full'>
              <ChartCercle />
            </div>
          </div>

          <div className='flex flex-col items-center justify-center'>
            <h1 className='text-center font-semibold mb-5'>NOMBRE TOTAL D'ABONNES</h1>
            <div className='flex items-center justify-center w-full'>
              <BarChart />
            </div>
          </div>

        </div>


        {/* <div>
          <p>{user_id}</p>
          <p>{token}</p>
        </div> */}
      </div>
      
    </div>
  )
}

export default Dashboard