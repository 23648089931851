import React from 'react'
import img3 from "../Assets/img3.jpg"
import { useNavigate } from 'react-router-dom'
import {motion} from "framer-motion"
import {fadeIn} from '../variant'

const Home = () => {

  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/connexion');
  }

  return (
    <div className='md:px-24 p-4 max-w-screen-2xl mx-auto mt-20' id='accueil'>
      <div className=''>
        <div className='flex flex-col md:flex-row-reverse justify-between items-center gap-10'>
          <motion.div
          variants={fadeIn("down", 0.2)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7}}
          >
            <img src={img3} alt="" className='lg:h-[386px]' />
          </motion.div>

          <motion.div
          variants={fadeIn("up", 0.2)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7}}
          className='md:w-3/5'>
            <h2 className='md:text-5xl max-sm:text-3xl text-4xl font-bold text-primary mb-6 leading-relaxed'>Gérez facilement votre <br /> comptabilité de base</h2>
            <p className='text-lg max-sm:text-lg mb-8'>Suivez vos finances, gérez vos factures et automatisez <br />
            vos transactions, tout en un seul endroit.</p>
            <div className='lg:space-x-10 lg:space-y-4 max-sm:space-x-5 max-sm:space-y-0'>
              <button className='py-1 px-5 bg-white font-medium text-primary rounded
              hover:bg-primary hover:text-white border-2 border-primary transition-all duration-300 lg:text-lg'  onClick={handleLoginClick}>Se connecter</button>
              <button className='py-1 px-5 bg-white font-medium text-primary rounded
              hover:bg-primary hover:text-white border-2 border-primary transition-all duration-300 lg:text-lg'>Nous contacter</button>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  )
}

export default Home