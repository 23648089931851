import React, { useEffect, useState } from 'react'
import SideBarKaonty from '../../../Components/Utilisateur/SideBar'
import Header from '../../../Components/Utilisateur/Header'
import { useNavigate, useParams } from 'react-router-dom'
import {EditOutlined} from '@ant-design/icons'
import img from '../../../Assets/photoUser.jpg'
import {Modal} from 'antd'
import InputProfil from '../../../Components/InputProfil'
import axios from 'axios'
import { api } from '../../../api'
import { Button, message, Space } from 'antd';
import PasswordModal from '../../../Components/PasswordModal'
import ModalImage from '../../../Components/ModalImage'

const Profil = () => {

  const user_id = sessionStorage.getItem('admin_id');
  const token = sessionStorage.getItem('token');
  const [loading, setLoading] = useState(true);

  const [openModalMod, setOpenModalMod] = useState(false);
  const [confirmLoadingMod, setConfirmLoadingMod] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();
  const infoIncomplete = () => {
    messageApi.open({
      type: 'info',
      content: 'Veuillez remplir tous les champs s\'il vous plaît.',
    });
  };

  const [oldUserInfo, setOldUserInfo] = useState({
    photo: '',
    nom: '',
    prenom: '',
    contact: '',
    adresse: '',
    email: '',
  })

  const fetchUserInfo = async () => {
    try {
      const response = await axios.get(api + '/api/user', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setOldUserInfo(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axios.get(api + '/api/user', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setOldUserInfo(response.data);
        setUserInfo(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    fetchUserInfo();
  }, [token]);

  const [userInfo, setUserInfo] = useState({
    photo: '',
    nom: '',
    prenom: '',
    contact: '',
    adresse: '',
    email: '',
  })

  const handleInputChange = (e) => {
      const { name, value } = e.target;
      setUserInfo({
        ...userInfo,
        [name]: value,
      });
    
  }

  const [selectedImage, setSelectedImage] = useState('');

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file); // Créer une URL pour l'image
      setSelectedImage(imageUrl); // Mettre à jour l'image sélectionnée
      // console.log(file)
      // setPhoto(file)
      setUserInfo({
        ...userInfo,
        photo: file,
      })
    }
  };

  const showModalMod = () => {
    setOpenModalMod(true);


  };

  const handleOkMod = () => {
    setConfirmLoadingMod(true);
    modInfo()
    setTimeout(() => {
      setOpenModalMod(false);
      setConfirmLoadingMod(false);
      // console.log(photo)
    }, 2000);
  };

  const handleCancelMod = () => {
    // console.log('Clicked cancel button');
    setOpenModalMod(false);
  };

  // Modification des info d'entité
  const modInfo = () => {
    if (userInfo.nom === '' || userInfo.prenom === '' || userInfo.contact === '' || userInfo.email === '' || userInfo.adresse === '')
    {
      infoIncomplete()
    }

    else{
      
      const formData = new FormData();
      formData.append('nom', userInfo.nom);
      formData.append('prenom', userInfo.prenom);
      formData.append('contact', userInfo.contact);
      formData.append('adresse', userInfo.adresse);
      formData.append('email', userInfo.email);

      if (userInfo.photo) {
        formData.append('photo', userInfo.photo);
      }
      
      try {
        axios.post(api + '/api/modInfoUser/' + oldUserInfo.id, formData)
            .then(res => {
              fetchUserInfo()

                // successModification()
                
            })
            .catch(err => {
                // Gérer les erreurs de la requête
                if (err.response) {
                    // Le serveur a répondu avec un code de statut différent de 2xx
                    console.error('Erreur réponse serveur:', err.response.data);
                } else if (err.request) {
                    // La requête a été faite, mais aucune réponse reçue
                    console.error('Aucune réponse reçue:', err.request);
                } else {
                    // Autre erreur lors de la configuration de la requête
                    console.error('Erreur lors de la requête:', err.message);
                }
            });
      } catch (error) {
          // Gérer les erreurs inattendues dans le bloc try
          console.error('Erreur inattendue:', error);
      }

    }
  } 

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOpenModal = () => setIsModalVisible(true);
  const handleCloseModal = () => setIsModalVisible(false);

  const handlePasswordChange = (values) => {
    console.log("Nouveau mot de passe :", values);
    // Envoyez `values` au backend pour la mise à jour du mot de passe
  };

  const [isModalVisibleImg, setIsModalVisibleImg] = useState(false);
  const [imageToShow, setImageToShow] = useState("");

  const handleImageClick = (imageUrl) => {
    setImageToShow(imageUrl);
    setIsModalVisibleImg(true);
  };

  const closeModal = () => {
    setIsModalVisibleImg(false);
    setImageToShow("");
  };

  return (
    <div className='bg flex flex-row' style={{ height: '100vh' }}>
        {contextHolder}
      <div>
        <SideBarKaonty />
      </div>
      <div className='bg w-full' style={{ background: '#F9F9FC' }}>
        <div>
          <Header titre={'Mon profil'}/>
        </div>

        <div className='pl-8 pr-7 py-6'>
          
          {/* Div en bas */}
          <div className='b border-2 mt-10 p-7 rounded-2xl'>

          {loading ? (
              // Spinner de chargement avec TailwindCSS
              <div className="flex justify-center items-center">
                <div className="w-12 h-12 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
              </div>
            ) : (

              <div className=''>
                  <div className='flex justify-between items-center mb-6'>
                    <div className='flex space-x-4 items-center'>
                      <img
                        src={oldUserInfo.photo ? api + '/storage/' + oldUserInfo.photo : img} // Afficher l'image de l'utilisateur ou une image par défaut
                        alt=""
                        className='w-20 h-20 object-cover rounded-full border-2 border-gray-300 hover:scale-105 transition-transform duration-300 cursor-pointer'
                        onClick={() => handleImageClick(oldUserInfo.photo)}
                      />
                      <div className='flex justify-center items-center'>
                        <p className='font-semibold text-2xl text-gray-800'>{oldUserInfo.nom} {oldUserInfo.prenom}</p>
                      </div>
                    </div>
                    <div>
                      <button className='px-6 py-2 font-semibold bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-300' onClick={showModalMod}>
                        Modifier <EditOutlined />
                      </button>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 mt-5">
                    {[
                      { label: "Nom et prénom(s)", value: `${oldUserInfo.nom} ${oldUserInfo.prenom}` },
                      { label: "Email", value: oldUserInfo.email },
                      { label: "Contact", value: oldUserInfo.contact },
                      { label: "Adresse", value: oldUserInfo.adresse }
                    ].map((info, idx) => (
                      <div key={idx} className="bg-gray-50 p-4 rounded-lg shadow-md">
                        <p className="font-semibold text-gray-700">{info.label}</p>
                        <p className="text-gray-600">{info.value || "Non disponible"}</p>
                      </div>
                    ))}
                  </div>
              </div>

            
            )}

          </div>

          {/* Div mot de passe */}
          <div className='border-2 mt-7 px-7 py-2 rounded-2xl'>
            <div className='flex justify-between'>
              <div className='flex justify-center items-center'>
                <span className='font-semibold'>Mot de passe</span>
              </div>
              <div>
                <button className='px-4 py-1 font-semibold bg-white text-primary duration-300 border-primary rounded-lg border-2' onClick={handleOpenModal}>Modifier <EditOutlined /></button>
              </div>
            </div>
          </div>

        </div>

      </div>

      {/* Modal de modification */}
      <Modal title="Modification du profil" open={openModalMod} onOk={handleOkMod} confirmLoading={confirmLoadingMod} onCancel={handleCancelMod}footer={(
              <div className="flex justify-center space-x-4">
                <button 
                  onClick={handleCancelMod} 
                  className="px-6 py-2 bg-gray-200 text-black rounded hover:bg-gray-300"
                >
                  Annuler
                </button>
                <button 
                  onClick={handleOkMod} 
                  className="px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  disabled={confirmLoadingMod}
                >
                  {confirmLoadingMod ? 'En cours...' : 'Enregistrer'}
                </button>
              </div>
            )}
          >

                  {/* <InputProfil
                    // initialImage={userInfo.photo ? `${api}/storage/${userInfo.photo}` : null}
                    initialImage={photo ? photo : null}
                    onImageChange={handleInputChange}
                  /> */}


                <div className="flex flex-col items-center justify-center">
                  {/* Champ de type file caché */}
                  <input
                    id="fileInput"
                    type="file"
                    accept="image/*"
                    className="hidden" 
                    onChange={handleFileChange} 
                  />

                  {/* Label circulaire pour déclencher l'upload */}
                  <label
                    htmlFor="fileInput"
                    className="cursor-pointer flex items-center justify-center w-32 h-32 bg-gray-200 rounded-full overflow-hidden border border-dashed border-gray-400 hover:bg-gray-300"
                  >
                    {selectedImage ? (
                      <img
                        src={selectedImage}
                        alt="Selected"
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <span className="text-gray-500 text-sm text-center">Sélectionner une image</span>
                    )}
                  </label>
                </div>

                <div className='space-y-1 mt-8 mb-2'>
                  <label className="font-semibold">Nom</label>
                  <input
                    id="nom"
                    name="nom"
                    type="text"
                    // autoComplete="email"
                    required
                    value={userInfo.nom}
                    onChange={handleInputChange}
                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Entrez la libellé"
                  />
                </div>

                <div className='space-y-1 mt-3 mb-2'>
                  <label className="font-semibold">Prénom(s)</label>
                  <input
                    id="prenom"
                    name="prenom"
                    type="text"
                    // autoComplete="email"
                    required
                    value={userInfo.prenom}
                    onChange={handleInputChange}
                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Entrez l'abréviation"
                  />
                </div>
                <div className='space-y-1 mt-3 mb-2'>
                  <label className="font-semibold">Contact</label>
                  <input
                    id="contact"
                    name="contact"
                    type="text"
                    // autoComplete="email"
                    required
                    value={userInfo.contact}
                    onChange={handleInputChange}
                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Entrez l'abréviation"
                  />
                </div>
                <div className='space-y-1 mt-3 mb-8'>
                  <label className="font-semibold">Adresse</label>
                  <input
                    id="adresse"
                    name="adresse"
                    type="text"
                    // autoComplete="email"
                    required
                    value={userInfo.adresse}
                    onChange={handleInputChange}
                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Entrez l'abréviation"
                  />
                </div>
      </Modal>
      
      <PasswordModal
        isVisible={isModalVisible}
        onClose={handleCloseModal}
        onSubmit={handlePasswordChange}
      />

      {/* Modal d'affichage */}
      {isModalVisibleImg && (
        <ModalImage
          imageUrl={imageToShow}
          visible={isModalVisibleImg}
          onClose={closeModal}
        />
      )}
    </div>
  )
}

export default Profil

